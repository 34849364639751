import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IUser } from "src/app/pages/online-order/dto/User";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserService{

    baseUrl = environment.baseUrl+'user/'

    constructor(private http: HttpClient){
    
    }

    saveUser(user: IUser){

        return this.http.post(this.baseUrl, user, {
            headers: new HttpHeaders({
                'token': localStorage.getItem('token')!
            })
        });

    }

    login(username: string, password: string):any{

        return this.http.post(this.baseUrl + 'login', {
            username: username,
            password: password
        })

    }

    getAllUsers():Observable<any>{

        return this.http.get(this.baseUrl, {
            headers: new HttpHeaders({
                'token': localStorage.getItem('token')!
            })
        })

    }

    deleteById(userId: string){

        return this.http.delete(this.baseUrl + userId, {
            headers: new HttpHeaders({
                'token': localStorage.getItem('token')!
            })
        })

    }

    getCurrent(): any{

        return this.http.get(this.baseUrl + 'current', {
            headers: new HttpHeaders({
                'token': localStorage.getItem('token')!
            })
        })

    }

}