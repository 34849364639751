import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningMessageService } from 'src/app/@Core/service/warning-message.service';
import { WarningMessage } from '../../dto/WarningMessage';
import { WashingInstruction } from '../../dto/WashingInstruction';

@Component({
  selector: 'app-washing-instructions-dialog',
  templateUrl: './washing-instructions-dialog.component.html',
  styleUrls: ['./washing-instructions-dialog.component.scss']
})
export class WashingInstructionsDialogComponent implements OnInit {

  instructionList: WarningMessage[][] = [
    [], [], [], []
  ]

  washingInstructionList: WarningMessage[] = [
    /*{
      GB:	'Wash with similar colours',			
      DE:	'Mit gleichen Farben waschen',			
      FR:	'Laver avec mêmes couleurs',			
      ES:	'Lavar con colores similares',		
      IT:	'Lavare con colori similari',			
      PT:	'Lavar com cores semelhantes',		
      GR:	'Πλύνετε με όμοια χρώματα',  
      CH: '洗水時要同类色一同洗水'
    },
    {
      GB:	'Wash inside out',			
      DE:	'Von links waschen',			
      FR:	'Laver retourné',			
      ES:	'Lavar del reves',		
      IT:	'Lavare al rovescio',			
      PT:	'Virar a peça do avesso para lavar',		
      GR:	'Πλύνετε από την ανάποδη',
      CH:	'要反衫洗'			

    },
    {
      GB:	'Wash with zipper closed',			
      DE:	'Waschen mit Reißverschluss geschlossen',			
      FR:	'Fermez la glissière fermée avant laver',			
      ES:	'Lavar con la cremallera cerrada',		
      IT:	'Lavare con cerniera chiusa',			
      PT:	'Lavar com fecho fechado',		
      GR:	'Πλενεται με κλειστο φερμουαρ',	  
      CH:	'请闭合拉链进行洗水'				
    },
    {
      GB:	'Handwash with softner',			
      DE:	'Handwäsche mit Weichspueler',			
      FR:	'Laver à main avec adoucissant',			
      ES:	'Lavar suavemente a mano',		
      IT:	'Lavare delicatamente a mano',			
      PT:	'Lavar a mão com amaciador',		
      GR:	'Πλύνετε απαλά με το χέρι',
      CH:	'手洗时请用织物柔软剂'				 

    },
    {
      GB:	'Use mild detergent',				
      DE:	'Feinwaschmittel gebrauchen',			
      FR:	'Utiliser un détergent doux',			
      ES:	'Utilizar detergente suave',			
      IT:	'Usare detergente delicato',				
      PT:	'Use detergente suave nas sedas',				
      GR:	'Χρησιμοποιήστε ήπιο απορρυπαντικό',			
      CH:	'使用温和的清洁剂'					  

    },
    {
      GB:	'Do not wash',			
      DE:	'Nicht waschen',			
      FR:	'Ne pas lavar',			
      ES:	'No lavar',		
      IT:	'Non lavare',			
      PT:	'Não lavar',		
      GR:	'Μην πλένετε',
      CH:	'不可水洗'			

    },
    {
      GB:	'Sponge clean only',			
      DE:	'Nur mit einem Schwamm reinigen',			
      FR:	'Nettoyer avec une éponge',			
      ES:	'Lavar solo con esponja',		
      IT:	'Lavare solo con spugna',			
      PT:	'Limpe com esponja',		
      GR:	'Καθαρισμα με σφουγγαρι μονο',
      CH:	'只有干净的海绵'	 

    },
    {
      GB:	'Hand wash',			
      DE:	'Handwäsche',			
      FR:	'Laver a la main',			
      ES:	'Lavar a mano',		
      IT:	'Lavare a mano',			
      PT:	'Lavar à mão',		
      GR:	'Πλύσιμο στο χέρι',
      CH:	'手洗'				

    },
    {
      GB:	'Hand wash with similar colors in cold water',			
      DE:	'Handwäsche im kalten Wasser mit ähnlichen Farben',			
      FR:	'Laver a la main avec couleurs de meme ton en eau froide',			
      ES:	'Lavar a mano con colores similares en agua fría',		
      IT:	'Lavare a mano con articoli di tinta similare in acqua fredda',			
      PT:	'Lavar à mão com artigos da mesma cor em água fria',		
      GR:	'Πλύσιμο στο χέρι με παρόμοια χρώματα σε κρύο νερό',
      CH:	'用相似的颜色在寒冷的水洗手'				

    },
    {
      GB:	'Delicate Program/gentle washing',			
      DE:	'Schonwaschgang',			
      FR:	'Lavage delicate',			
      ES:	'Adrorne de piel',		
      IT:	'Lavaggio delicato',			
      PT:	'Lavagem suave',		
      GR:	'Απαλό πλύσιμο',
      CH:	'精致的程序/温柔的洗'				

    },
    {
      GB:	'Not washable',			
      DE:	'Nicht waschbar',			
      FR:	'Non lavable',			
      ES:	'No lavar',		
      IT:	'Non lavabile',			
      PT:	'Não lavavel',		
      GR:	'Μην πλένετε',
      CH:	'不能洗水'	

    },
    {
      GB:	'Wash separately',			
      DE:	'Separat waschen',			
      FR:	'Laver séparément',			
      ES:	'Lavar por separado',		
      IT:	'Lavare separatamente',			
      PT:	'Lavar separado',		
      GR:	'Πλύνετε ξεχωριστά',
      CH:	'单独洗水 或者 分开洗水'			

    },
    {
      GB:	'Handwash only',			
      DE:	'Nur per hand waschen',			
      FR:	'Seulement laver a main',			
      ES:	'Solamente lavar a mano',		
      IT:	'Solo lavaggio a mano',			
      PT:	'So lavar as maos',		
      GR:	'Μόνο πλύσιμο χεριών',
      CH:	'只能手洗'				

    },
    {
      GB:	'Delicate machine wash 30°',			
      DE:	'Schonwaschgang 30°',			
      FR:	'Lavage délicat 30°',			
      ES:	'Lavado delicado 30°',		
      IT:	'Lavaggio delicato 30°',			
      PT:	'Lavagem suave 30°',		
      GR:	'Πλυσιμο στο προγραμμα για ευαισθητα στουσ 30°',
      CH:	'精致的程序/温柔的洗 30°'

    },
    {
      GB:	'Hand wash in cold water only',			
      DE:	'Vorsichtig in kaltem Wasser per Hand waschen',			
      FR:	"Laver avec précaution à la main à l'eau froide",			
      ES:	'Atención, lavar a mano con agua fría',		
      IT:	'Lavare attentamente a mano in acqua fredda',			
      PT:	'Lavar cuidadosamente à mão em água fria',		
      GR:	'Πλενεται στο χερι σε κρυο νερο',
      CH:	'小心在冷水中手洗'	

    },
    {
      GB:	'Machine wash 30°C extra gentle',			
      DE:	'Maschinenwäsche 30°C extra Pflegeleicht',			
      FR:	'Lavage délicat special en machine à 30°C',			
      ES:	'Lavado a máquina 30°C Extra fácil cuidado',		
      IT:	'Lavaggio in lavatrice a 30℃ extra leggero',			
      PT:	'Lavagem na máquina a 30°C especialmente fácil de limpar',		
      GR:	'Πλύσιμο στο πλυντήριο στους 30°c ιδιαίτερα ευαίσθητα',
      CH:	'30°C超级轻柔机洗'				

    }*/
  ]

  dryCleaningInstructionList: WarningMessage[] = [
    /*{
      GB:	'Dry Clean only',
      DE:	'Nur chemisch reinigen',
      FR:	'Nettoyage a sec seulement',
      IT:	'Lavare solo a secco',
      ES:	'Limpieza en seco',
      PT:	'Limpeza a seco',
      GR:	'Μόνο στεγνό καθάρισμα',
      CH:	'只能干洗'
    }*/
  ]

  ironingInstructionList: WarningMessage[] = [
    /*{
      GB: 'Do not iron on print/badge',
      DE:	'Drücke/Etikett nicht bügeln',
      FR:	'Ne pas repasser l’imprimé/l’étiquette',
      ES:	'No planchar el estampado/emblem',
      IT:	'Non stirare le stampe/badge',
      PT:	'Não passar a ferro sobre o estampado',
      GR:	'Μη σιδερώνετε τη στάμπα / στρίφωμα',
      CH:	'不能在熨於印花/徽章'
    },
    {
      GB: 'Cool iron on reverse side',
      DE:	'Kühl von links bügeln',
      FR:	"Repassage doux sur l'envers",
      ES:	"Planchar a baja temperatura por el revés",
      IT:	"stirare sul rovescio con ferro tiepido",
      PT:	"passar do avesso com ferro morno",
      GR:	"Σιδερωνεται σε χαμηλη θερμοκρασια απο την αναποδη",
      CH:	"反转衫低温熨",
    },
    {
      GB: "Do not iron on sequins",
      DE:	"Pailletten nicht bugeln",
      FR:	"Ne pas repasser les paillettes",
      ES:	"No planchar las lentejuelas",
      IT:	"Non stirare le paillettes",
      PT:	"Nao passer a ferro as lantejoulas",
      GR:	"Μη σιδερωνετε τα διακοσμητικα (πουλιεσ)",
      CH:	"不能熨于钉珠上"
    },
    {
      GB: "Do not iron on embellishment.",
      DE:	"Bitte nicht auf der Verzierung bügeln",
      FR:	"Ne pas repasser sur l'embellissement",
      ES:	"No planchar sobre decoración",
      IT:	"Non stirare sul decoro",
      PT:	"Não passar a ferro a decoração",
      GR:	"Μην σιδερωνετε τη διακοσμηση",
      CH:	"不能熨于装饰品上"
    },
    {
      GB:	"Do not iron",
      DE:	"Nicht Bügeln",
      FR:	"Ne pas Repasser",
      ES:	"No Planchar",
      IT:	"Non Stirare",
      PT:	"Não Passar",
      GR:	"Μη σιδερώνετε",
      CH:	"不能熨"
    },
    {
      GB: "Iron on reverse",
      DE:	"Auf links bügeln",
      FR:	"Repassage sur l'envers",
      ES:	"Planchar al revés",
      IT:	"Stirare al rovescio", 
      PT:	"Engomar do avesso",
      GR:	"Σίδερο στην πίσω πλευρά",
      CH:	"背面熨烫"
    },
    {
      GB: "Iron whilst damp",
      DE:	"Feucht bügeln",
      FR:	"Repasser avec la vapeur",
      ES:	"Planchar humedo",
      IT:	"Stirare da umido",
      PT:	"Passar a ferro enquanto estiver humido",
      GR:	"Σιδερωνεται υγρο",
      CH:	"在湿润时熨烫"
    }*/
  ]

  extraInstructionList: WarningMessage[] = [
    /*{
      GB:	"Colour may fade after repeated exposure to chlorinated water",
      DE:	"Farbe kann nach wiederholten Kontakt mit chlorierten Wassser verblassen",
      FR:	"La Couleur peut s’estomper après une exposition répétée à l'eau chlorée",
      ES:	"El color puede destenirse después de la exposición repetida al agua clorada",
      IT:	"Il colore potrebbe stingere dopo ripetute esposizioni ad acqua clorata",
      PT:	"A cor pode desbotar após exposição repetida a água com cloro",
      GR:	"Μπορει να αποχρωματιστει απο επανειλλημενη εκθεση σε νερο με χλωριο",
      CH:	"经过反复暴露于含氯的水,颜色可能会褪色"
    },
    {
      GB:	"Cleaning by leather specialist only",
      DE:	"Reinigung nur bei Lederspezialist",
      FR:	"Entretien par un specialiste du cuir",
      ES:	"Lavado unicamente por un especialista del cuero",
      IT:	"Lavare a secco in lavanderia specializzata nella pulitura della pelle",
      PT:	"Lavar em lavandaria especializada em pele/couro",
      GR:	"Ειδικός καθαρισμός δέρματος",
      CH:	"只能用皮革专业清洗"
    },
    {
      GB:	"Remove all detachable accessories before washing",
      DE:	"Bitte entfernen Sie alle abnehmbaren Zubehoerteile vor dem Waschen",
      FR:	"Enlever tous les éléments détachables avant lavage",
      ES:	"Sacar todos los accesorios removible antes de limpiar",
      IT:	"Rimuovere tutti gli accessori staccabili prima del lavaggio",
      PT:	"Retire todos os acessorios amovíveis antes de limpar",
      GR:	"Αφαιρεστε ολα τα αποσπωμενα διακοσμητικα πριν την πλυση",
      CH:	"洗衣之前除去全部可拆式的配件"
    },
    {
      GB:	"Pull into shape whilst damp",
      DE:	"Im feuchten zustand in form ziehen",
      FR:	"Tirer dans tout forme humide",
      ES:	"Dat forma mientras la prenda este humeda",
      IT:	"Mettere in forma a capo umido",
      PT:	"Enformar a peca ainda humida",
      GR:	"Τραβηξτε να επανελθει η φορμα του οσο ειναι υγρο",
      CH:	"在潮湿时拉成型"
    }*/
  ]

  selectedOptions:any[] = [
    [], [], [], [], []
  ];

  result: any = {
    instructions: [],
    leftMargin: 0,
    rightMargin: 0,
    textSize: 0,
  } 

  constructor(
    public dialogRef: MatDialogRef<WashingInstructionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private warningMessagesService: WarningMessageService
  ) {
    this.result.instructions = data.instructions;
    this.result.leftMargin = data.leftMargin;
    this.result.rightMargin = data.rightMargin;
    this.result.textSize = data.textSize;

    console.log(this.result)
   }

  ngOnInit(): void {

    this.warningMessagesService.getWarningListByType('WASHING').subscribe((res: any[]) => {
      this.instructionList[0] = res;
      this.instructionList[0].sort((a, b) => {

        return a.order - b.order 

      })
    })
    this.warningMessagesService.getWarningListByType('IRONING').subscribe((res: any[]) => {
      this.instructionList[1] = res;
    })
    this.warningMessagesService.getWarningListByType('DRY_CLEANING').subscribe((res: any[]) => {
      this.instructionList[2] = res;
    })
    this.warningMessagesService.getWarningListByType('EXTRA').subscribe((res: any[]) => {
      this.instructionList[3] = res;
    })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(){
    /*this.result.instructions = []
    this.selectedOptions.forEach(list => {
      list.forEach((item: any) => {
        console.log(item)
        this.instructionList.forEach(iList => {
          let ins = iList.filter(x => x.id == item)[0]
          if(ins)
            this.result.instructions.push(ins)
        })
      })
    })*/
    console.log(this.result)
    this.dialogRef.close(this.result)
  }

  isSelected(symbol: WashingInstruction, index: number) {
    let array = this.result.instructions.map(item => item.id)
    console.log(array)
    console.log(symbol.id)
    console.log(array.includes(symbol.id))
    //console.log(this.readSelectedOptions)
    //console.log(this.selectedOptions[index].includes(symbol))
    //console.log(this.selectedOptions)
    return array.includes(symbol.id)
    //return selected.length == 1;
  }

  clickEvent(symbol, index: number){

    let array = this.result.instructions.map(item => item.id)
    console.log(array)
    if(array.includes(symbol.id)){
      let itemIndex = array.indexOf(symbol.id)
      console.log(itemIndex)
      this.result.instructions.splice(itemIndex, 1)
    }else{
      this.result.instructions.push(symbol)
    }
  }

}
