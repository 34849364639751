import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlineOrderComponent } from './online-order.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { OnlineOrderRoutingModule } from './online-order-routing.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CreateWashingInstructionsComponent } from './create-washing-instructions/create-washing-instructions.component';
import { MatSelectModule } from '@angular/material/select';
import { OriginCountryDialogComponent } from './create-washing-instructions/origin-country-dialog/origin-country-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SymbolDialogComponent } from './create-washing-instructions/symbol-dialog/symbol-dialog.component';
import {MatListModule} from '@angular/material/list';
import { FabricOptionsDialogComponent } from './create-washing-instructions/fabric-options-dialog/fabric-options-dialog.component';
import { WashingInstructionsDialogComponent } from './create-washing-instructions/washing-instructions-dialog/washing-instructions-dialog.component';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {TableModule} from 'primeng/table';
import { TooolbarComponent } from 'src/app/layouts/toolbar/tooolbar/tooolbar.component';
import { FooterComponent } from 'src/app/layouts/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { ChineseLabelDialogComponent } from './create-washing-instructions/chinese-label-dialog/chinese-label-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {MatSidenavModule} from '@angular/material/sidenav';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressBarModule} from 'primeng/progressbar';
import { UsersComponent } from './users/users.component';
import { WarningComponent } from './warning/warning.component';
import {DialogModule} from 'primeng/dialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ProductComponent } from './product/product.component';
import { FabricFeatureComponent } from './fabric-feature/fabric-feature.component';
import { OriginCountryComponent } from './origin-country/origin-country.component';
import { WarningLabelComponent } from './warning-label/warning-label.component';
import { WarningSymbolsComponent } from './warning-symbols/warning-symbols.component';
import { ProductModelComponent } from './product-model/product-model.component';
import { ListWashingInstructionsComponent } from './create-washing-instructions/list-washing-instructions/list-washing-instructions.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CompletedWashingInstructionComponent } from './create-washing-instructions/completed-washing-instruction/completed-washing-instruction.component';
import { ListCompletedComponent } from './create-washing-instructions/list-completed/list-completed.component';
import { RussianTypeDialogComponent } from './create-washing-instructions/russian-type-dialog/russian-type-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartModule } from 'primeng/chart';


@NgModule({
  declarations: [
    LoginComponent,
    OnlineOrderComponent,
    CreateWashingInstructionsComponent,
    OriginCountryDialogComponent,
    SymbolDialogComponent,
    FabricOptionsDialogComponent,
    WashingInstructionsDialogComponent,
    TooolbarComponent,
    FooterComponent,
    ChineseLabelDialogComponent,
    UsersComponent,
    WarningComponent,
    ProductComponent,
    FabricFeatureComponent,
    OriginCountryComponent,
    WarningLabelComponent,
    WarningSymbolsComponent,
    ProductModelComponent,
    ListWashingInstructionsComponent,
    CompletedWashingInstructionComponent,
    ListCompletedComponent,
    RussianTypeDialogComponent,
    DashboardComponent,

  ],
  
  imports: [
    OnlineOrderRoutingModule,
    CommonModule,
    MatIconModule,
    FontAwesomeModule,

    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatListModule,
    MatCheckboxModule,


    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatGridListModule,
    MatDividerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,

     //primeng
    SidebarModule,
    ButtonModule,
    MenubarModule,
    PanelMenuModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    ProgressBarModule,
    DialogModule,
    RadioButtonModule,
    ConfirmDialogModule,
    ChartModule,



  ],
  providers:[ConfirmationService,MessageService],
  exports:[TooolbarComponent,
    FooterComponent]
})
export class OnlineOrderModule { }
