<div class="example-sidenav-content">
  <div class="p-3">
    <p-table #dt styleClass="p-datatable-customers" [value]="users"
      [globalFilterFields]="['name','username','password','userRole','company']" [rows]="10"
      [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="row" style="width: 100%;">
            <div class="col-md-8">
              List of Users
            </div>
            <div class="col-md-3">
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Search" />
            </div>
            <div class="col-md-1">
              <button mat-raised-button (click)="openModalDialog(user, 'create')" color="primary">Create User</button>
            </div>
          </div>


        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="username" style="font-size: 9pt;">Kullanıcı adı <p-sortIcon field="username">
            </p-sortIcon>
          </th>
          <th pSortableColumn="password">Şifre <p-sortIcon field="password"></p-sortIcon>
          </th>
          <th pSortableColumn="firstName">Adı Soyadı <p-sortIcon field="firstName"></p-sortIcon>
          </th>
          <th pSortableColumn="company">Şirket Adı <p-sortIcon field="company"></p-sortIcon>
          </th>
          <th pSortableColumn="userRole">Görevi <p-sortIcon field="userRole"></p-sortIcon>
          </th>
          <th>
          </th>
          <th>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{user.username}}</td>
          <td>{{user.password}}</td>
          <td>{{user.firstName}} {{user.lastName}}</td>
          <td>{{user.company}}</td>
          <td>{{user.userRole}}</td>
          <td><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openModalDialog(user, 'edit')"></button></td>
          <td><button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openDeleteDialog(user)"></button></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No User found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- edit  dialog -->
<p-dialog [(visible)]="displayDialog" [style]="{'width':'45%'}">
  <ng-template pTemplate="header">
    KULLANICI DÜZENLE
  </ng-template>
  <div class="p-fluid p-grid">
    <div class="row">
      <!-- name input -->
      <div class="field col-6 input-class">
        <label>Ad</label>
        <input class="form-control" id="name" aria-describedby="emailHelp" [(ngModel)]="this.user.firstName">
      </div>
      <!-- surname input -->
      <div class="field col-6 input-class">
        <label>Soyad</label>
        <input class="form-control" id="surname" aria-describedby="emailHelp" [(ngModel)]="this.user.lastName">
      </div>
    </div>
    <div class="row">
      <!-- email input -->
      <div class="field col-6 input-class">
        <label>E-posta</label>
        <input type="email" class="form-control" id="email" aria-describedby="emailHelp" [(ngModel)]="this.user.email">
      </div>
      <!-- company input -->
      <div class="field col-6 input-class">
        <label>Şirket Adı</label>
        <input class="form-control" id="companyName" aria-describedby="emailHelp" [(ngModel)]="this.user.company">
      </div>
    </div>

    <div class="row">

      <!-- company input -->
      <div class="field col-6 input-class">
        <label>Kullanıcı Adı</label>
        <input class="form-control" id="username" aria-describedby="emailHelp"
          [(ngModel)]="this.user.username">
      </div>
      <div class="field col-6 input-class">
        <label>Şifre</label>
        <input type="password" class="form-control" id="password" aria-describedby="emailHelp"
          [(ngModel)]="this.user.password">
      </div>
      
    </div>

    <div class="row">
      <div class="field col-12 input-class">
        <label>Yetki</label>
        <div *ngFor="let role of roles" class="field-checkbox">
          <div class="row" style="padding-left: 2%;">
            <p-radioButton [inputId]="role.key" name="role" [value]="role.key" [(ngModel)]="this.user.userRole">
            </p-radioButton>
            <label [for]="role.key">{{role.name}}</label>

          </div>
          
        </div>
      </div>

    </div>
    


  </div>
  <ng-template pTemplate="footer">
    <p-button label="Kaydet" (click)="editUser()"></p-button>
    <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
      (click)="hideDialog()"></button>
  </ng-template>
</p-dialog>


<p-dialog [(visible)]="displayDeleteDialog" [style]="{'width':'45%'}">
  <ng-template pTemplate="header">
    KULLANICI SİL
  </ng-template>
  <div class="p-fluid p-grid">
    <div class="row">
      <div class="col-12" style="padding: 2%;">
        Kullanıcıyı silmek istediğinize emin misiniz?
      </div>      
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="Evet" (click)="deleteUser()"></p-button>
    <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
      (click)="hideDialog()"></button>
  </ng-template>
</p-dialog>