import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { WarningSymbolService } from 'src/app/@Core/service/warning-symbol.service';
import { WarningSymbol } from '../dto/WarningSymbol';

@Component({
  selector: 'app-warning-symbols',
  templateUrl: './warning-symbols.component.html',
  styleUrls: ['./warning-symbols.component.scss'],
})
export class WarningSymbolsComponent implements OnInit {
  selectedWarning: any = {
    id: null,
    order: 1,
    filePath: '',
    washingSymbol: 'WASHING',
  };

  selectedCategory: string = '';
  languageCode: any;
  text: any;
  displayDialog: boolean = false;
  categories: string[] = ['WASHING', 'IRONING', 'DRY_CLEANING', 'EXTRA'];
  warnings: any[] = []

  areFieldsDisabled = true;
  displayDeleteDialog = false;
  constructor(private warningService: WarningSymbolService) {}

  ngOnInit(): void {
    this.warningService.getAllWashingSymbols().subscribe(res => {
      this.warnings = res
      console.log(res)
    },
    err => {



    })
  }

  // close modal dialog
  hideDialog() {
    //clear attirbutes
    this.selectedWarning = {
      id: '',
      order: 1,
      filePath: '',
      washingSymbol: 'WASHING',
    };

    this.displayDialog = false;
    this.areFieldsDisabled = true;
    this.displayDeleteDialog = false;
  }

  // open modal dialog
  openModalDialog(warning: any, modalType: string) {
    // set selected row's data

    if (modalType == 'edit') this.areFieldsDisabled = false;
    this.selectedWarning = warning;

    this.displayDialog = true;
  }

  // edit selected warning row
  editWarning() {
    this.warningService.saveWashingSymbol(this.selectedWarning).subscribe(
      () => {
        this.warningService.getAllWashingSymbols().subscribe(
          (res) => {
            this.warnings = res;
          },
          (err) => {}
        );
        this.hideDialog();
      },
      (err) => {
        this.hideDialog();
      }
    );
  }

  openCreateDialog() {
    this.areFieldsDisabled = false;
    this.displayDialog = true;
  }

  openDeleteDialog(warning: any) {
    this.displayDeleteDialog = true;
    this.selectedWarning = warning;
  }

  // delete selected warning row
  deleteWarning() {
    this.warningService
      .deleteWashingLabelById(this.selectedWarning.id)
      .subscribe(
        () => {
          this.warningService.getAllWashingSymbols().subscribe(
            (res) => {
              this.warnings = res;
            },
            (err) => {}
          );
          this.hideDialog();
        },
        (err) => {
          this.hideDialog();
        }
      );
  }

  customSort(event: SortEvent) {
    event?.data?.sort((data1, data2) => {
      let value1 = data1[event.field!];
      let value2 = data2[event?.field!];
      let result = 0;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order! * result;
    });
  }
}
