<app-tooolbar></app-tooolbar>
<div class="container">
    <mat-card>
        <mat-card-title>Login</mat-card-title>
        <mat-card-content>
            <form [formGroup]="form">

                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput [formControl]="form.controls['username']" #username>
                    <mat-error *ngIf="form.controls['username'].hasError('required')">
                        Username is <strong>required</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput [formControl]="form.controls['password']" #password>
                    <mat-error *ngIf="form.get(['password'])!.hasError('required')">
                        Password is <strong>required</strong>
                    </mat-error>
                </mat-form-field>


                <div class="button row">
                    <button  (click)="login(username.value, password.value)"
                     mat-stroked-button color="primary">Login</button>
                </div>

            </form>
        </mat-card-content>
    </mat-card>
</div>
