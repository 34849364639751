<h1 mat-dialog-title>Fabric Options</h1>
<div class="content" mat-dialog-content>
    <div *ngFor="let fabric of fabrics">
        <div class="row">
            <mat-form-field class="col-md-9" appearance="fill">
                <mat-label>Product Parts</mat-label>
                <mat-select [(value)]="fabric.productPart">
                    <mat-option *ngFor="let part of productsParts" [value]="part.id">
                        {{part.value['gb']}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button class="col-md-2 remove-button" (click)="removeProductPart(fabric, fabrics)" mat-stroked-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="row" *ngFor="let feat of fabric.features">
            <mat-form-field class="col-md-5" appearance="fill">
                <mat-label>Part Fabric</mat-label>
                <mat-select [(value)]="feat.name">
                    <mat-option *ngFor="let feature of fabricFeatures" [value]="feature.id">
                        {{feature.value.gb}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4" appearance="fill">
                <mat-label>Percentage (%)</mat-label>
                <input [(ngModel)]="feat.ratio" type="number" matInput />
            </mat-form-field>
            <button class="col-md-2 remove-button" (click)="removeFabric(fabric, feat)" mat-stroked-button>
                <mat-icon>delete_forever</mat-icon>
            </button>
        </div>
        <button (click)="addFabric(fabric.features)" class="add-fabric" mat-stroked-button color="primary">Add
            Fabric</button>
        <mat-divider style="margin-bottom: 10px;"></mat-divider>

    </div>
    <button (click)="addProductPart()" class="add-fabric" mat-stroked-button color="primary">Add Product Part</button>
    <mat-divider></mat-divider>

    <section style="margin: 4%;">
        <mat-checkbox class="example-margin" (change)="onRusCheckChanged(rusChecked)" [(ngModel)]="rusChecked">Add Russian</mat-checkbox>
    </section>

    <div *ngIf="rusChecked">
        <div *ngFor="let fabric of rusFabrics">
            <div class="row">
                <mat-form-field class="col-md-9" appearance="fill">
                    <mat-label>Product Parts</mat-label>
                    <mat-select [(value)]="fabric.productPart">
                        <mat-option *ngFor="let part of productsParts" [value]="part.id">
                            {{part.value['gb']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="col-md-2 remove-button" (click)="removeProductPart(fabric, rusFabrics)" mat-stroked-button>
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="row" *ngFor="let feat of fabric.features">
                <mat-form-field class="col-md-5" appearance="fill">
                    <mat-label>Part Fabric</mat-label>
                    <mat-select [(value)]="feat.name">
                        <mat-option *ngFor="let feature of fabricFeatures" [value]="feature.id">
                            {{feature.value.gb}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-4" appearance="fill">
                    <mat-label>Percentage (%)</mat-label>
                    <input [(ngModel)]="feat.ratio" type="number" matInput />
                </mat-form-field>
                <button class="col-md-2 remove-button" (click)="removeFabric(fabric, feat)" mat-stroked-button>
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div>
            <button (click)="addFabric(fabric.features)" class="add-fabric" mat-stroked-button color="primary">Add
                Fabric</button>
            <mat-divider style="margin-bottom: 10px;"></mat-divider>

        </div>
        <button (click)="addRussianProductPart()" class="add-fabric" mat-stroked-button color="primary">Add Product
            Part</button>
        <mat-divider></mat-divider>
    </div>


    <section style="margin: 4%;">
        <mat-checkbox class="example-margin" (change)="onChineseCheckChanged(chineseChecked)" [(ngModel)]="chineseChecked">Add Chinese</mat-checkbox>
    </section>

    <div *ngIf="chineseChecked">
        <div *ngFor="let fabric of chineseFabrics">
            <div class="row">
                <mat-form-field class="col-md-9" appearance="fill">
                    <mat-label>Product Parts</mat-label>
                    <mat-select [(value)]="fabric.productPart">
                        <mat-option *ngFor="let part of productsParts" [value]="part.id">
                            {{part.value['gb']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="col-md-2 remove-button" (click)="removeProductPart(fabric, chineseFabrics)" mat-stroked-button>
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="row" *ngFor="let feat of fabric.features">
                <mat-form-field class="col-md-5" appearance="fill">
                    <mat-label>Part Fabric</mat-label>
                    <mat-select [(value)]="feat.name">
                        <mat-option *ngFor="let feature of fabricFeatures" [value]="feature.id">
                            {{feature.value.gb}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-4" appearance="fill">
                    <mat-label>Percentage (%)</mat-label>
                    <input [(ngModel)]="feat.ratio" type="number" matInput />
                </mat-form-field>
                <button class="col-md-2 remove-button" (click)="removeFabric(fabric, feat)" mat-stroked-button>
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div>
            <button (click)="addFabric(fabric.features)" class="add-fabric" mat-stroked-button color="primary">Add
                Fabric</button>
            <mat-divider style="margin-bottom: 10px;"></mat-divider>

        </div>
        <button (click)="addChineseProductPart()" class="add-fabric" mat-stroked-button color="primary">Add Product
            Part</button>
        <mat-divider></mat-divider>
    </div>


    <!--<div class="row">
        <mat-form-field class="col-md-12" appearance="fill">
            <mat-label>Text Size (pt)</mat-label>
            <input type="number" matInput [(ngModel)]="data.textSize" />
        </mat-form-field>

    </div>
    
    <div class="row">
        <mat-form-field class="col-md-6" appearance="fill">
            <mat-label>Left Margin (mm)</mat-label>
            <input type="number" matInput [(ngModel)]="data.leftMargin" />
        </mat-form-field>
        <mat-form-field class="col-md-6" appearance="fill">
            <mat-label>Right Margin (mm)</mat-label>
            <input type="number" matInput [(ngModel)]="data.rightMargin" />
        </mat-form-field>

    </div>-->
</div>
<div mat-dialog-actions style="float: right;">
    <button mat-button (click)="onNoClick()">Close</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>