<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Washing">
        <mat-selection-list >
            <mat-list-option *ngFor="let instruction of instructionList[0]" 
                [value]="instruction.id"
                [selected]="isSelected(instruction, 0)"
                (click)="clickEvent(instruction, 0)"
                style="display:inline-block; width:33%;  " 
                checkboxPosition="before">
                {{instruction.value['gb']}}
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
    <mat-tab label="Dry Cleaning">
        <mat-selection-list >
            <mat-list-option *ngFor="let instruction of instructionList[2]" 
                [value]="instruction.id"
                [selected]="isSelected(instruction, 1)"
                (click)="clickEvent(instruction, 1)"
                style="display:inline-block; width:33%;  " 
                checkboxPosition="before">
                {{instruction.value['gb']}}
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
    <mat-tab label="Ironing">
        <mat-selection-list >
            <mat-list-option *ngFor="let instruction of instructionList[1]" 
                [value]="instruction.id"
                [selected]="isSelected(instruction, 2)"
                (click)="clickEvent(instruction, 2)"
                style="display:inline-block; width:33%;  " 
                checkboxPosition="before">
                {{instruction.value['gb']}}
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
    <mat-tab label="Extras">
        <mat-selection-list >
            <mat-list-option *ngFor="let instruction of instructionList[3]" 
                [value]="instruction.id"
                [selected]="isSelected(instruction, 3)"
                (click)="clickEvent(instruction, 3)"
                style="display:inline-block; width:33%;  " 
                checkboxPosition="before">
                {{instruction.value['gb']}}
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
</mat-tab-group>
<div mat-dialog-actions style="float: right;">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>
