import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FabricFeatureService } from 'src/app/@Core/service/fabric-feature.service';
import { ProductService } from 'src/app/@Core/service/product.service';
import { fabricFeaturesPart } from '../../dto/FabricFeaturePart';
import { ProductPart } from '../../dto/ProductPart';

@Component({
  selector: 'app-fabric-options-dialog',
  templateUrl: './fabric-options-dialog.component.html',
  styleUrls: ['./fabric-options-dialog.component.scss']
})
export class FabricOptionsDialogComponent implements OnInit {

  productsParts:ProductPart[] = [
    /*{
      GB: 'Back',
      DE: 'Hinter',
      FR: 'Arrière',
      ES: 'Detrás',
      IT: 'Dietro',
      PT: 'Trás',
      GR: 'Πίσω μέρος',
      RU: 'Задняя часть',
      CH: '后片'
    },
    {
      GB: 'Back part',
      DE: 'Hinterteil',
      FR: 'Partie arrière',
      ES: 'Parte trasera',
      IT: 'Parte de trás',
      PT: 'Parte de trás',
      GR: 'Πλάτη',
      RU: 'Спинка',
      CH: '后片'
    },
    {
      GB: 'Body lining',
      DE: 'Rumpffutter',
      FR: 'Doublure corps',
      ES: 'Forro del cuerpo',
      IT: 'Fodera Corpo',
      PT: 'Forro do Corpo',
      GR: 'Φόδρα σώματος',
      RU: 'Основная подкладка',
      CH: '身里'
    },
    {
      GB: 'Bottom',
      DE: 'Unten',
      FR: 'Bas',
      ES: 'Inferior',
      IT: 'Parte inferiore',
      PT: 'Parte de baixo',
      GR: 'Κάτω μέρος',
      RU: 'Нижняя часть',
      CH: '下摆'
    },
    {
      GB: 'Collar',
      DE: 'Kragen',
      FR: 'Col',
      ES: 'Cuello',
      IT: 'Collo',
      PT: 'Colarinho',
      GR: 'Γιακάς',
      RU: 'Воротник',
      CH: '颌'
    },
    {
      GB: 'Cuffs',
      DE: 'Manschette',
      FR: 'Poignet',
      ES: 'Puños',
      IT: 'Polsino',
      PT: 'Punho',
      GR: 'Μανσέτα',
      RU: 'Манжеты',
      CH: '袖口'
    },
    {
      GB: 'Filling',
      DE: 'Wattierung',
      FR: 'Garnissage',
      ES: 'Relleno',
      IT: 'Imbottitura',
      PT: 'Enchimento',
      GR: 'Γέμισμα',
      RU: 'Наполнитель',
      CH: '夹里'
    },
    {
      GB: 'Front',
      DE: 'Vorne',
      FR: 'Devant',
      ES: 'Delantero',
      IT: 'Davanti',
      PT: 'Frente',
      GR: 'Μπροστινό μέρος',
      RU: 'Передняя часть',
      CH: '前片'
    },
    {
      GB: 'Hood lining',
      DE: 'Kapuzenfutter',
      FR: 'Doublure capuche',
      ES: 'Forro de capucha',
      IT: 'Fodera cappuccio',
      PT: 'Forro do capuz',
      GR: 'Eσωτερική φόδρα',
      RU: 'Подкладка капюшона',
      CH: '帽里'
    },
    {
      GB: 'Interlining',
      DE: 'Innenfutter',
      FR: 'Entre interieur',
      ES: 'Tela adhesiva',
      IT: 'Controfodera',
      PT: 'Entretela',
      GR: 'Εσωτερική φόδρα',
      RU: 'Подкладка',
      CH: '内里'
    },
    {
      GB: 'Lining',
      DE: 'Futter',
      FR: 'Doublure',
      ES: 'Forro',
      IT: 'Fodera',
      PT: 'Forro',
      GR: 'Φόδρα',
      RU: 'Подкладка',
      CH: '衬里'
    },
    {
      GB: 'Other parts',
      DE: 'Andere teile',
      FR: 'Autres parties',
      ES: 'Otras parties',
      IT: 'Altre parti',
      PT: 'Outras partes',
      GR: 'Άλλα μέρη',
      RU: 'Другие части',
      CH: '其他部分'
    },
    {
      GB: 'Part 1',
      DE: 'Teil 1',
      FR: 'Partie 1',
      ES: 'Parte 1',
      IT: 'Parte 1',
      PT: 'Parte 1',
      GR: 'Μέρος 1',
      RU: 'Часть 1',
      CH: '部位1'
    },
    {
      GB: 'Part 2',
      DE: 'Teil 2',
      FR: 'Partie 2',
      ES: 'Parte 2',
      IT: 'Parte 2',
      PT: 'Parte 2',
      GR: 'Μέρος 2',
      RU: 'Часть 2',
      CH: '部位2'
    },
    {
      GB: 'Part 3',
      DE: 'Teil 3',
      FR: 'Partie 3',
      ES: 'Parte 3',
      IT: 'Parte 3',
      PT: 'Parte 3',
      GR: 'Μέρος 3',
      RU: 'Часть 3',
      CH: '部位3'
    },
    {
      GB: 'Part 4',
      DE: 'Teil 4',
      FR: 'Partie 4',
      ES: 'Parte 4',
      IT: 'Parte 4',
      PT: 'Parte 4',
      GR: 'Μέρος 4',
      RU: 'Часть 4',
      CH: '部位4'
    },
    {
      GB: 'Ribbed fabric',
      DE: 'Rippware',
      FR: 'Tricot « côte »',
      ES: 'Tejido rib',
      IT: 'Materiale a coste',
      PT: 'Tecido rasgado',
      GR: 'ύφασμα με ραβδώσεις',
      RU: 'Ткань в рубчик',
      CH: '罗纹'
    },
    {
      GB: 'Shell',
      DE: 'Oberstoff',
      FR: 'Tissu principal',
      ES: 'Exterior',
      IT: '(Materiale) esterno',
      PT: 'Exterior',
      GR: 'Εξωτερικό ύφασμα',
      RU: 'Основной состав',
      CH: '面布'
    },
    {
      GB: 'Sleeve',
      DE: 'Ärmel',
      FR: 'Manche',
      ES: 'Manga',
      IT: 'Manica',
      PT: 'Manga',
      GR: 'Μανίκια',
      RU: 'Рукава',
      CH: '袖'
    },
    {
      GB: 'Sleeve lining',
      DE: 'Äermelfutter',
      FR: 'Doublure Manche',
      ES: 'Forro manga',
      IT: 'Fodera manica',
      PT: 'Forro manga',
      GR: 'Φόδρα μανικιού',
      RU: 'Подкладка рукава',
      CH: '袖里'
    },
    {
      GB: 'Trim',
      DE: 'Besatz',
      FR: 'Passementerie',
      ES: 'Ribete',
      IT: 'Guarnizione',
      PT: 'Aplique',
      GR: 'Φινίρισμα',
      RU: 'Отде́лка',
      CH: '辅料'
    },
    {
      GB: 'Waistband',
      DE: 'Bund',
      FR: 'Ceinture',
      ES: 'Pretina',
      IT: 'Cintura',
      PT: 'Cintura',
      GR: 'Μέση',
      RU: 'Тесьма',
      CH: '腰带'
    },
    {
      GB: 'Woven',
      DE: 'Gewoben',
      FR: 'Tissé',
      ES: 'Tejido',
      IT: 'Tessuto',
      PT: 'Tecido',
      GR: 'Υφασμα',
      RU: 'Тканый',
      CH: '梳织'
    }*/
  ]

  fabricFeatures: fabricFeaturesPart[] = [
    /*{
      GB: 'Acetate',
      DE: 'Avetat',
      FR: 'Acetate',
      ES: 'Acetato',
      IT: 'Acetato',
      PT: 'Acetato',
      GR: 'Ασετατ',
      RU: 'Ацетат',
      CH: '醋酯纤维'
    },
    {
      GB: 'Acrylic',
      DE: 'Polyacryl',
      FR: 'Acrylique',
      ES: 'Acrilico',
      IT: 'Acrilico',
      PT: 'Acrilico',
      GR: 'Ακρυλικο',
      RU: 'Акрил',
      CH: '聚丙烯腈纤维'
    },
    {
      GB: 'Acrylic - Recycled',
      DE: 'Polyacre - Recyclebare',
      FR: 'Acrylique - Recyclé',
      ES: 'Acrilico - Reciclado',
      IT: 'Acrilico - Riciclato',
      PT: 'Acrilico - Reciclado',
      GR: 'Ακρυλικό ανακυκλωμένο',
      RU: 'Переработанный акрил',
      CH: '聚丙烯腈纤维 回收利用'
    },
    {
      GB: 'Alpaca',
      DE: 'Alpaka',
      FR: 'Alpaga',
      ES: 'Alpaca',
      IT: 'Alpaca',
      PT: 'Alpaca',
      GR: 'Αιγοκάμηλος',
      RU: 'Альпака',
      CH: '羊驼毛'
    },
    {
      GB: 'Angora',
      DE: 'Angorakanin',
      FR: 'Angora',
      ES: 'Angora',
      IT: 'Angora',
      PT: 'Angora',
      GR: 'Ανγκορα',
      RU: 'Ангора (ангорский кролик)',
      CH: '安哥拉兔毛'
    },
    {
      GB: 'Bamboo',
      DE: 'Bambus',
      FR: 'bambou',
      ES: 'Bamboo',
      IT: 'Bambù',
      PT: 'Bambu',
      GR: 'Μπαμπού',
      RU: 'Бамбук',
      CH: '竹纤维'
    },
    {
      GB: 'Cashmere',
      DE: 'Kaschmir',
      FR: 'Cachemire',
      ES: 'Cachemir',
      IT: 'Cashmere',
      PT: 'Cashemere',
      GR: 'Κασμιρι',
      RU: 'Кашемир',
      CH: '山羊绒'
    },
    {
      GB: 'Cotton',
      DE: 'Baumwolle',
      FR: 'Coton',
      ES: 'Algodon',
      IT: 'Cotone',
      PT: 'Algodão',
      GR: 'Βαμβακι',
      RU: 'Хлопок',
      CH: '棉'
    },
    {
      GB: 'Cotton - Organic',
      DE: 'Baumwolle - Bio',
      FR: 'Coton - Organique',
      ES: 'Algodon - Orgánico',
      IT: 'Cotone - Organico',
      PT: 'Algodão - Orgânico',
      GR: 'Οργανικο βαμβάκι',
      RU: 'Органический хлопок',
      CH: '有机棉'
    },
    {
      GB: 'Cotton - Recycled',
      DE: 'Baumwolle - Recyclebare',
      FR: 'Coton - Recyclé',
      ES: 'Algodon - Reciclado',
      IT: 'Cotone - Riciclato',
      PT: 'Algodão - Reciclado',
      GR: 'Ανακυκλωμένο βαμβάκι',
      RU: 'Переработанный хлопок',
      CH: '棉回收利用'
    },
    {
      GB: 'Cotton (Supima®)',
      DE: 'Baumwolle (Supima®)',
      FR: 'Coton (Supima®)',
      ES: 'Algodon (Supima®)',
      IT: 'Cotone (Supima®)',
      PT: 'Algodão (Supima®)',
      GR: 'Βαμβακι (Supima®)',
      RU: 'Хлопок (Supima®)',
      CH: '棉 (Supima®)'
    },
    {
      GB: 'Cupro',
      DE: 'Cupro',
      FR: 'Cupro',
      ES: 'Cupro',
      IT: 'Cupro',
      PT: 'Cupro',
      GR: 'Κουπρο',
      RU: 'Купра',
      CH: '铜氨纤维'
    },
    {
      GB: 'Down',
      DE: 'Daunen',
      FR: 'Duvet',
      ES: 'Plumon',
      IT: 'Piuma',
      PT: 'Plumas',
      GR: 'Πουπουλο',
      RU: 'Пух',
      CH: '羽绒'
    },
    {
      GB: 'Elastane',
      DE: 'Elasthan',
      FR: 'Elasthanne',
      ES: 'Elastan',
      IT: 'Elastan',
      PT: 'Elastano',
      GR: 'Ελαστανη',
      RU: 'Эластан',
      CH: '聚氨酯弹性纤维'
    },
    {
      GB: 'Elastane (T400®)',
      DE: 'Elasthan (T400®)',
      FR: 'Elasthanne (T400®)',
      ES: 'Elastan (T400®)',
      IT: 'Elastan (T400®)',
      PT: 'Elastano (T400®)',
      GR: 'Ελαστανη (T400®)',
      RU: 'Эластан (T400®)',
      CH: '聚氨酯弹性纤维 (T400®)'
    },
    {
      GB: 'Elastane (Lycra®)',
      DE: 'Elasthan (Lycra®)',
      FR: 'Elasthanne (Lycra®)',
      ES: 'Elastan (Lycra®)',
      IT: 'Elastan (Lycra®)',
      PT: 'Elastano (Lycra®)',
      GR: 'Ελαστανη (Lycra®)',
      RU: 'Эластан (Lycra®)',
      CH: '聚氨酯弹性纤维 (Lycra®)'
    },
    {
      GB: 'Elastomultiester',
      DE: 'Elastomultiester',
      FR: 'Élastomultiester',
      ES: 'Elastomultiéster',
      IT: 'Elastomultiestere',
      PT: 'Elastomultiéster',
      GR: 'Ελαστοπολυεστερασ',
      RU: 'Эластомультиэстер',
      CH: '弹性多聚酯纤维(T400®)'
    },
    {
      GB: 'Feathers',
      DE: 'Federn',
      FR: 'Plumes',
      ES: 'Pluma',
      IT: 'Piume',
      PT: 'Penas',
      GR: 'Πουπουλο πτηνου',
      RU: 'Перья',
      CH: '羽毛'
    },
    {
      GB: 'Goose Down',
      DE: 'Gänse Daunen',
      FR: "Duvet d''oie",
      ES: 'Pluma de Ganso',
      IT: "Piuma d'oca",
      PT: 'Penas de Ganso',
      GR: 'Πουπουλο χηνασ',
      RU: 'Гусиный пух',
      CH: '鹅绒'
    },
    {
      GB: 'Hemp',
      DE: 'Hanf',
      FR: 'Chanvre',
      ES: 'Canamo',
      IT: 'Canapa',
      PT: 'Cânhamo',
      GR: 'Κανναβη',
      RU: 'Конопля',
      CH: '大麻'
    },
    {
      GB: 'Lambswool',
      DE: 'Lammwolle',
      FR: "Laine d'agneau",
      ES: 'Lana de cordero',
      IT: "Lana d'agnello",
      PT: 'Lã de ovelha',
      GR: 'Εριον',
      RU: 'Шерсть ягнёнка',
      CH: '羊仔毛'
    },
    {
      GB: 'Leather',
      DE: 'Leder',
      FR: 'Cuir',
      ES: 'Piel',
      IT: 'Pelle',
      PT: 'Pele',
      GR: 'Δερμα',
      RU: 'кожа',
      CH: '皮革'
    },
    {
      GB: 'Linen',
      DE: 'Leinen',
      FR: 'Lin',
      ES: 'Lino',
      IT: 'Lino',
      PT: 'Linho',
      GR: 'Λιναρι',
      RU: 'Лен',
      CH: '亚麻布'
    },
    {
      GB: 'Lyocell',
      DE: 'Lyocell',
      FR: 'Lyocell',
      ES: 'Lyocell',
      IT: 'Lyocell',
      PT: 'Lyocell',
      GR: 'Λυοσελ',
      RU: 'Лиоцелл',
      CH: '莱赛尔纤维'
    },
    {
      GB: 'Lyocell (Tencel™)',
      DE: 'Lyocell (Tencel™)',
      FR: 'Lyocell (Tencel™)',
      ES: 'Lyocell (Tencel™)',
      IT: 'Lyocell (Tencel™)',
      PT: 'Lyocell (Tencel™)',
      GR: 'Λυοσελ (Tencel™)',
      RU: 'Лиоцелл (Tencel™)',
      CH: '莱赛尔纤维 (Tencel™)'
    },
    {
      GB: 'Merino wool',
      DE: 'Merinowolle',
      FR: 'Laine Merinos',
      ES: 'Lana Merina',
      IT: 'Lana merino',
      PT: 'Lã de merino',
      GR: 'Μαλλι μερινοσ',
      RU: 'Шерсть мериносовой овцы',
      CH: '美利奴羊毛'
    },
    {
      GB: 'Metal',
      DE: 'Metall',
      FR: 'Metal',
      ES: 'Metal',
      IT: 'Metallo',
      PT: 'Metal',
      GR: 'Μέταλλο',
      RU: 'Металл',
      CH: '金属'
    },
    {
      GB: 'Metallic fibre',
      DE: 'Metallische Faser',
      FR: 'Gibre Metallique',
      ES: 'Fibra metalica',
      IT: 'Fibra Metallica',
      PT: 'Fibra metálica',
      GR: 'Μεταλλικη ινα',
      RU: 'Металлизированное волокно',
      CH: '金属纤维'
    },
    {
      GB: 'Modacrylic',
      DE: 'Modacryl',
      FR: 'Modacrylique',
      ES: 'Modacrilica',
      IT: 'Modacrilica',
      PT: 'Modacrílica',
      GR: 'Μοντακρυλικο',
      RU: 'Модакрил',
      CH: '腈氯纶'
    },
    {
      GB: 'Modal',
      DE: 'Modal',
      FR: 'Modal',
      ES: 'Modal',
      IT: 'Modal',
      PT: 'Modal',
      GR: 'Μονταλ',
      RU: 'Модал',
      CH: '莫代尔纤维'
    },
    {
      GB: 'Modal (Tencel™)',
      DE: 'Modal (Tencel™)',
      FR: 'Modal (Tencel™)',
      ES: 'Modal (Tencel™)',
      IT: 'Modal (Tencel™)',
      PT: 'Modal (Tencel™)',
      GR: 'Μονταλ (Tencel™)',
      RU: 'Модал (Tencel™)',
      CH: '莫代尔纤维 (Tencel™)'
    },
    {
      GB: 'Mohair',
      DE: 'Mohair',
      FR: 'Mohair',
      ES: 'Mohair',
      IT: 'Mohair',
      PT: 'Mohair',
      GR: 'Μοχαιρ',
      RU: 'Мохер',
      CH: '马海毛'
    },
    {
      GB: 'Nylon',
      DE: 'Polyamid',
      FR: 'Polyamide',
      ES: 'Poliamida',
      IT: 'Poliammide',
      PT: 'Poliamida',
      GR: 'Ναϋλον',
      RU: 'Нейлон',
      CH: '锦纶'
    },
    {
      GB: 'Other Fibres',
      DE: 'Andere Fasern',
      FR: 'Autres fibres',
      ES: 'Otras fibras',
      IT: 'Altre fibre',
      PT: 'Otras Fibras',
      GR: 'Αλλεσ ινεσ',
      RU: 'Другие волокна',
      CH: '其他纤维'
    },
    {
      GB: 'Other Fibres recycled',
      DE: 'Andere Fasern Recyclebare',
      FR: 'Autres fibres recyclé',
      ES: 'Otras fibras recicladas',
      IT: 'Altre fibre riciclate',
      PT: 'Otras Fibras recicladas',
      GR: 'Άλλες ανακυκλωμένες ίνες',
      RU: 'Другие переработанные волокна',
      CH: '其他再生纖維'
    },
    {
      GB: 'Paper',
      DE: 'Papier',
      FR: 'Papier',
      ES: 'Papel',
      IT: 'Di carta',
      PT: 'Papel',
      GR: 'Χαρτι',
      RU: 'Бумага',
      CH: '纸'
    },
    {
      GB: 'Polyester',
      DE: 'Polyester',
      FR: 'Polyester',
      ES: 'Poliestere',
      IT: 'Poliestere',
      PT: 'Poliester',
      GR: 'Πολυεστερασ',
      RU: 'Полиэстер',
      CH: '聚酯纤维'
    },
    {
      GB: 'Polyester - Recycled',
      DE: 'Polyester - Recyclebare',
      FR: 'Polyester - Recyclé',
      ES: 'Poliestere - Reciclado',
      IT: 'Poliestere - Riciclato',
      PT: 'Poliester - Reciclado',
      GR: 'Ανακυκλωμένος πολυεστέρας',
      RU: 'Переработанный полиэстер',
      CH: '聚酯纤维回收利用'
    },
    {
      GB: 'Polyethylene',
      DE: 'Polyethylen',
      FR: 'Polyéthylène',
      ES: 'Polietileno',
      IT: 'Polietilene',
      PT: 'Polietileno',
      GR: 'Ίνα πολυαιθυλενίου',
      RU: 'Полиэтилен',
      CH: '聚乙烯'
    },
    {
      GB: 'Polypropylene',
      DE: 'Polypropylen',
      FR: 'Polypropylène',
      ES: 'Polipropileno',
      IT: 'Polipropilene',
      PT: 'Polipropileno',
      GR: 'Πολυπροπυλένιο',
      RU: 'Полипропилен',
      CH: '聚丙烯䊹維'
    },
    {
      GB: 'Polyurethane',
      DE: 'Polyurethan',
      FR: 'Polyrethane',
      ES: 'Poliuretano',
      IT: 'Poliuretano',
      PT: 'Poliuterano',
      GR: 'Πολυουρεθανη',
      RU: 'Полиуретан',
      CH: '聚氨酯'
    },
    {
      GB: 'Ramie',
      DE: 'Ramie',
      FR: 'Ramier',
      ES: 'Ramio',
      IT: 'Ramie',
      PT: 'Ramia',
      GR: 'Ραμι',
      RU: 'Рами',
      CH: '苎麻'
    },
    {
      GB: 'Silk',
      DE: 'Seide',
      FR: 'Soie',
      ES: 'Seda',
      IT: 'Seta',
      PT: 'Seda',
      GR: 'Μεταξι',
      RU: 'Шелк',
      CH: '丝纤维'
    },
    {
      GB: 'Straw',
      DE: 'Stro',
      FR: 'Paille',
      ES: 'Paja',
      IT: 'Paglia',
      PT: 'Palha',
      GR: 'Αχυρο',
      RU: 'Солома',
      CH: '稻草'
    },
    {
      GB: 'Virgin wool',
      DE: 'Schurwolle',
      FR: 'Laine Vierge',
      ES: 'Lana virgen',
      IT: 'Lana vergine',
      PT: 'Lã Virgem',
      GR: 'Παρθένο μαλλί',
      RU: 'Шерсть',
      CH: '羊毛'
    },
    {
      GB: 'Viscose',
      DE: 'Viskose',
      FR: 'Viscose',
      ES: 'Viscosa',
      IT: 'Viscosa',
      PT: 'Viscose',
      GR: 'Βισκοζη',
      RU: 'Вискоза',
      CH: '粘胶纤维'
    },
    {
      GB: 'Viscose (Ecovero™)',
      DE: 'Viskose (Ecovero™)',
      FR: 'Viscose (Ecovero™)',
      ES: 'Viscosa (Ecovero™)',
      IT: 'Viscosa (Ecovero™)',
      PT: 'Viscose (Ecovero™)',
      GR: 'Βισκοζη (Ecovero™)',
      RU: 'Вискоза (Ecovero™)',
      CH: '粘胶纤维 (Ecovero™)'
    },
    {
      GB: 'Wool',
      DE: 'Wolle',
      FR: 'Laine',
      ES: 'Lana',
      IT: 'Lana',
      PT: 'Lã',
      GR: 'Μαλλι',
      RU: 'Шерсть',
      CH: '羊毛'
    },
    {
      GB: 'Wool - Organic',
      DE: 'Wolle - Bio',
      FR: 'Laine - Organique',
      ES: 'Lana - Organico',
      IT: 'Lana - Organico',
      PT: 'Lã - Orgânico',
      GR: 'Μαλλι - Οργανικο',
      RU: 'Органическая шерсть',
      CH: '有机羊毛'
    },
    {
      GB: 'Wool - Recycled',
      DE: 'Wolle - Recyclebare',
      FR: 'Laine - Recyclé',
      ES: 'Lana - Reciclado',
      IT: 'Lana - Riciclato',
      PT: 'Lã - Reciclado',
      GR: 'Μαλλι - Ανακυκλωμένο',
      RU: 'Переработанная шерсть',
      CH: '再生羊毛'
    },
    {
      GB: 'Polyamide - Recycled',
      DE: 'Polyamid - Recyclebare',
      FR: 'Polyamide - Recyclé',
      ES: 'Poliamida - Reciclado',
      IT: 'Poliammide - Riciclato',
      PT: 'Poliamida - Reciclado',
      GR: 'Ανακυκλωμένος Ναϋλον',
      RU: 'Переработанный Полиамид',
      CH: '锦纶回收利用'
    },
    {
      GB: 'Linen - Organic',
      DE: 'Leinen - Bio',
      FR: 'Lin - Organique',
      ES: 'Lino Orgánico',
      IT: 'Lino - Organico',
      PT: 'Linho Orgánico',
      GR: 'λινάρι - Βιολογικό',
      RU: 'Органический Лён',
      CH: '有机亚麻'
    },
    {
      GB: 'Cashmere - Recycled',
      DE: 'Kaschmir - Recyclebare',
      FR: 'Cachemire - Recyclé',
      ES: 'Cachemir - Reciclado',
      IT: 'Cashmere - Riciclato',
      PT: 'Cashemere - Reciclado',
      GR: 'Κασμίρ ανακυκλωμένο',
      RU: 'Переработанный Кашемир',
      CH: '再生羊绒'
    },
    {
      GB: 'Sheep Nubuck',
      DE: 'Schaaf Nubuck',
      FR: 'Nubuck de mouton',
      ES: 'Piel Nabuck',
      IT: 'Pelle Scamosciata Nabuck',
      PT: 'Nubuck de carneiro',
      GR: 'Δέρμα ναμπούκ',
      RU: 'Нубук из овечьей шкуры',
      CH: '羊皮革'
    },
    {
      GB: 'Sheep Nappa',
      DE: 'Schafsnappa',
      FR: 'Mouton Nappa',
      ES: 'Napa de cordero',
      IT: 'Nappa di montone',
      PT: 'Napa de carneiro',
      GR: 'Δέρμα νάπα από πρόβατο',
      RU: 'Кожа наппа из овечьей шкуры',
      CH: '绵羊皮'
    },
    {
      GB: 'Sheep Leather',
      DE: 'Schaaf Leder',
      FR: 'Cuir de mouton',
      ES: 'Piel ovejas',
      IT: 'Pelle ovina',
      PT: 'Cabedal de carneiro',
      GR: 'Δερμα προβατου',
      RU: 'Нат. кожа - овца',
      CH: '羊皮革'
    },*/
  ]

  fabrics = [
    {
      productPart: '',
      features: [
        {
          name: '',
          ratio: 0
        },
      ]
    }
  ]

  rusFabrics = [
    {
      productPart: '',
      features: [
        {
          name: '',
          ratio: 0
        },
      ]
    }
  ]

  chineseFabrics = [
    {
      productPart: '',
      features: [
        {
          name: '',
          ratio: 0
        },
      ]
    }
  ]

  result: {
    normal: any[],
    rus: any[],
    chinese: any[],
    leftMargin: number,
    rightMargin: number,
    textSize: number,
  } = {
      normal: [],
      rus: [],
      chinese: [],
      leftMargin: 0,
      rightMargin: 0,
      textSize: 0,
    };

  rusChecked: boolean = false;
  chineseChecked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FabricOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productPartService: ProductService,
    private fabricFeatureService: FabricFeatureService
  ) {

    if (data.normal.length > 0) {

      this.fabrics = []
      this.rusFabrics = []
      this.chineseFabrics = []
      let fabricList: any[] = data.normal;

      if (data.rus.length > 0) {
        this.rusChecked = true;

        let rusFabricList: any[] = data.rus;

        rusFabricList.forEach(item => {

          let features: { name: string; ratio: number; }[] = []
          item.features.forEach((i: { value: fabricFeaturesPart; ratio: any; }) => {

            features.push({
              name: i.value.id,
              ratio: i.ratio
            })

          })
          this.rusFabrics.push({
            productPart: item.productPart.id,
            features: features
          })
        });
      }
      if (data.chinese.length > 0){
        this.chineseChecked = true;
        
        let chineseFabricList: any[] = data.chinese;

        chineseFabricList.forEach(item => {

          let features: { name: string; ratio: number; }[] = []
          item.features.forEach((i: { value: fabricFeaturesPart; ratio: any; }) => {

            features.push({
              name: i.value.id,
              ratio: i.ratio
            })

          })
          this.chineseFabrics.push({
            productPart: item.productPart.id,
            features: features
          })
        });

      }



      fabricList.forEach(item => {

        let features: { name: string; ratio: number; }[] = []
        item.features.forEach((i: { value: fabricFeaturesPart; ratio: any; }) => {

          features.push({
            name: i.value.id,
            ratio: i.ratio
          })

        })
        this.fabrics.push({
          productPart: item.productPart.id,
          features: features
        })
      });

    }
    console.log(this.fabrics)
  }

  ngOnInit(): void {

    this.productPartService.getAllProducts().subscribe(res => {
      
      this.productsParts = res
      console.log(this.productsParts)
    })

    this.fabricFeatureService.getAllFabrics().subscribe(res => {

      this.fabricFeatures = res

    })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    console.log(this.fabrics)
    this.fabrics.forEach(fabric => {


      let filtered = this.productsParts.find(part =>
        part.id === fabric.productPart
      )

      if (filtered) {

        let features: ({ value:fabricFeaturesPart, ratio: number})[] = [];
        fabric.features.forEach(feature => {
          features.push({
            value: this.fabricFeatures.find(fabricFeature => feature.name === fabricFeature.id)!,
            ratio: feature.ratio
          })
        })
        this.result.normal.push({
          productPart: filtered,
          features: features
        })

      }

    })

    this.rusFabrics.forEach(fabric => {


      let filtered = this.productsParts.find(part =>
        part.id === fabric.productPart
      )

      if (filtered) {

        let features: ({ value:fabricFeaturesPart, ratio: number})[] = [];
        fabric.features.forEach(feature => {
          features.push({
            value: this.fabricFeatures.find(fabricFeature => feature.name === fabricFeature.id)!,
            ratio: feature.ratio
          })
        })
        this.result.rus.push({
          productPart: filtered,
          features: features
        })

      }

    })

    this.chineseFabrics.forEach(fabric => {


      let filtered = this.productsParts.find(part =>
        part.id === fabric.productPart
      )

      if (filtered) {

        let features: ({ value:fabricFeaturesPart, ratio: number})[] = [];
        fabric.features.forEach(feature => {
          features.push({
            value: this.fabricFeatures.find(fabricFeature => feature.name === fabricFeature.id)!,
            ratio: feature.ratio
          })
        })
        this.result.chinese.push({
          productPart: filtered,
          features: features
        })

      }

    })

    if (this.result.rus.length === 0) {
      //this.result.rus = this.result.normal
    }

    this.result.leftMargin = this.data.leftMargin;
    this.result.rightMargin = this.data.rightMargin;
    this.result.textSize = this.data.textSize;
    console.log(this.result)
    this.dialogRef.close(this.result)

  }

  addFabric(features: ({ name: string, ratio: number })[]) {
    features.push({
      name: '',
      ratio: 0
    })
  }

  removeFabric(part: ({ productPart: string, features: ({ name: string, ratio: number })[] }), feature: ({ name: string, ratio: number })) {
    console.log(part)
    let index = part.features.indexOf(feature);
    part.features.splice(index, 1)
  }

  addProductPart() {
    this.fabrics.push({
      productPart: '',
      features: [
        {
          name: '',
          ratio: 0
        },
      ]
    })

    console.log(this.fabrics)
  }

  removeProductPart(part: ({ productPart: string, features: ({ name: string, ratio: number })[] }), fabrics){
    let index = fabrics.indexOf(part);
    fabrics.splice(index, 1)
  }

  addRussianProductPart() {
    this.rusFabrics.push({
      productPart: '',
      features: [
        {
          name: '',
          ratio: 0
        },
      ]
    })

    console.log(this.fabrics)
  }

  addChineseProductPart() {
    this.chineseFabrics.push({
      productPart: '',
      features: [
        {
          name: '',
          ratio: 0
        },
      ]
    })

  }

  onRusCheckChanged(checked){
    if(checked)
      this.fabrics.forEach(item => {

        let features: ({name: string, ratio: number})[] = []
        for(let feature of item.features){
          features.push({
            name: feature.name,
            ratio: feature.ratio
          })
        }
        this.rusFabrics.push({
          productPart: item.productPart,
          features: features
        })

      })
    else
      this.rusFabrics = [];  
    
  }

  onChineseCheckChanged(checked){
    if(checked)
      this.fabrics.forEach(item => {

        let features: ({name: string, ratio: number})[] = []
        for(let feature of item.features){
          features.push({
            name: feature.name,
            ratio: feature.ratio
          })
        }
        this.chineseFabrics.push({
          productPart: item.productPart,
          features: features
        })

      })
    else
      this.chineseFabrics = [];  
  }

}
