<div class="certificates-container">
    <div class="title">CERTIFICATES</div>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <img src="https://static.wixstatic.com/media/e27322_974ccc42b2d243d5aef6e446cde88438~mv2.png/v1/fill/w_200,h_256,al_c,usm_0.66_1.00_0.01,enc_auto/iso%209001-2015.png" />
                <div class="text">See Certificate</div>
            </div>
            <div class="col-md-3">
                <img src="https://static.wixstatic.com/media/e27322_7d193b9ebd3b4b118b8666038d5fdda3~mv2.png/v1/fill/w_382,h_203,al_c,lg_1,enc_auto/OTS100_label_04_T_edited.png" />
                <div class="text">See Certificate</div>
            </div>
            <div class="col-md-3">
                <img src="https://static.wixstatic.com/media/e27322_68621b781c6044d3b1bb5fb72dce2471~mv2.png/v1/crop/x_0,y_0,w_1024,h_1502/fill/w_174,h_256,al_c,usm_0.66_1.00_0.01,enc_auto/FSC_C125430_Promotional_with_text_Portrait_WhiteOnGreen_r_57zb9R_PNG.png" />
                <div class="text">See Certificate</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <img src="https://static.wixstatic.com/media/e27322_f5062a481f5640c19d9590af7b3605e4~mv2.png/v1/fill/w_282,h_170,al_c,usm_0.66_1.00_0.01,enc_auto/Global%20Recycled%20Standard.png" />
                <div class="text">See Certificate</div>
            </div>
            <div class="col-md-3">
                <img src="https://static.wixstatic.com/media/e27322_76884731fcc840fcbdb8c3631c317774~mv2.png/v1/fill/w_200,h_236,al_c,usm_0.66_1.00_0.01,enc_auto/iso%2014001-2015.png" />
                <div class="text">See Certificate</div>
            </div>
            <div class="col-md-3">
                <img src="https://static.wixstatic.com/media/e27322_8991da08ffb9464997cc817953928242~mv2.png/v1/fill/w_254,h_254,al_c,usm_0.66_1.00_0.01,enc_auto/gots.png" />
                <div class="text">See Certificate</div>
            </div>
        </div>
    </div>
</div>

