import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductModal } from 'src/app/pages/online-order/dto/ProductModal';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductModelService {

  productModals=[
    {
      id:'1',
      name:'K79-Gloves',
      text:'Перчатки'
    },
    {
      id:'1',
      name:'K77-Miscellaneous',
      text:'Защитная маска для лица'
    }
  ]

  constructor(private http: HttpClient) {}

  getAllProducts():Observable<any>{
    return this.http.get(environment.baseUrl+'type/rus', {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }


  saveProduct(product: ProductModal) {
    return this.http.post(environment.baseUrl+'type/rus', product, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }

  getProductListByType(type: string) {
    return this.http.get(`${environment.baseUrl}/${type}`, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }

  deleteProductById(id: string) {
    return this.http.delete(`${environment.baseUrl}type/rus/${id}`, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }
}
