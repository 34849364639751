import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  data: any = {};

  constructor(private http: HttpClient) { }

  
  use(lang: string): Promise<{}> {
    return new Promise<{}>(resolve => {
      const languagePath = `../../../assets/i18n/${lang + ".json" || 'en.json'}`;

      this.http.get(languagePath).subscribe(
        response => {
          this.data = response || {};
          resolve(this.data);
        },
        err => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }
}
