import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooolbarComponent } from './layouts/toolbar/tooolbar/tooolbar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { AboutComponent } from './pages/about/about.component';
import { LanguageModule } from './language.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { MissionComponent } from './pages/mission/mission.component';
import { WorkComponent } from './pages/work/work.component';
import { ReferenceComponent } from './pages/reference/reference.component';
import { CertificatesComponent } from './pages/certificates/certificates.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlineOrderModule } from './pages/online-order/online-order.module';
import { LoginComponent } from './pages/online-order/login/login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    //TooolbarComponent,
    //FooterComponent,
    AboutComponent,
    HomepageComponent,
    MissionComponent,
    WorkComponent,
    ReferenceComponent,
    CertificatesComponent,
    ContactsComponent,
    DefaultLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,

    //angular material
    MatTabsModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatGridListModule,
    MatButtonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,

    OnlineOrderModule,
    HttpClientModule,
  ],
  exports: [
    DefaultLayoutComponent,
    TooolbarComponent,
    FooterComponent,
    LanguageModule,
    HttpClientModule,
    CommonModule,
  ],
  providers: [HttpClient],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faWhatsapp, faInstagram, faFacebook);
  }
}
