import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChineseSizeService } from 'src/app/@Core/service/chinese-size.service';

@Component({
  selector: 'app-chinese-label-dialog',
  templateUrl: './chinese-label-dialog.component.html',
  styleUrls: ['./chinese-label-dialog.component.scss']
})
export class ChineseLabelDialogComponent implements OnInit {

  womenTop = [
    {
      normal: 'XS',
      chinese: '155/80A'
    },
    {
      normal: 'S',
      chinese: '160/84A'
    },
    {
      normal: 'M',
      chinese: '165/88A'
    },
    {
      normal: 'L',
      chinese: '170/92A'
    },
    {
      normal: 'XL',
      chinese: '175/100A'
    }
  ]

  womenBottom = [
    {
      normal: '24/XS',
      chinese: '155/62A'
    },
    {
      normal: '25/XS',
      chinese: '155/64A'
    },
    {
      normal: '26/S',
      chinese: '160/66A'
    },
    {
      normal: '27/S',
      chinese: '160/68A'
    },
    {
      normal: '28/M',
      chinese: '165/70A'
    },
    {
      normal: '29/M',
      chinese: '165/74A'
    },
    {
      normal: '30/L',
      chinese: '170/76A'
    },
    {
      normal: '31/L',
      chinese: '170/78A'
    },
    {
      normal: '32/XL',
      chinese: '175/80A'
    },
    {
      normal: 'XL',
      chinese: '175/84A'
    }
  ]

  menTop = [
    {
      normal: 'S',
      chinese: '165/92A'
    },
    {
      normal: 'M',
      chinese: '170/96A'
    },
    {
      normal: 'L',
      chinese: '175/100A'
    },
    {
      normal: 'XL',
      chinese: '180/108A'
    },
    {
      normal: 'XXL',
      chinese: '185/116A'
    }
  ]

  menTopPrm = [
    {
      normal: '46',
      chinese: '165/92A'
    },
    {
      normal: '48',
      chinese: '170/96A'
    },
    {
      normal: '50',
      chinese: '175/100A'
    },
    {
      normal: '52',
      chinese: '180/108A'
    },
    {
      normal: '54',
      chinese: '185/116A'
    }
  ]

  menBottom = [
    {
      normal: '28/S',
      chinese: '165/76A'
    },
    {
      normal: '29/S',
      chinese: '165/80A'
    },
    {
      normal: '30/M',
      chinese: '170/82A'
    },
    {
      normal: '31/M',
      chinese: '170/84A'
    },
    {
      normal: '32/L',
      chinese: '175/86A'
    },
    {
      normal: '33/L',
      chinese: '175/90A'
    },
    {
      normal: '34/XL',
      chinese: '180/92A'
    },
    {
      normal: '36/XL',
      chinese: '180/96A'
    },
    {
      normal: '38/XXL',
      chinese: '185/100A'
    }
  ]

  menBottomPrm = [
    {
      normal: '46',
      chinese: '165/80A'
    },
    {
      normal: '48',
      chinese: '170/84A'
    },
    {
      normal: '50',
      chinese: '175/86A'
    },
    {
      normal: '52',
      chinese: '180/92A'
    },
    {
      normal: '54',
      chinese: '185/100A'
    }
  ]

  girlsTop = [
    {
      normal: '4',
      chinese: '110/56'
    },
    {
      normal: '6',
      chinese: '120/60'
    },
    {
      normal: '8',
      chinese: '130/64'
    },
    {
      normal: '10',
      chinese: '140/68'
    },
    {
      normal: '12',
      chinese: '150/76'
    },
    {
      normal: '14',
      chinese: '160/82'
    },
    {
      normal: '16',
      chinese: '170/86A'
    }
  ]

  girlsBottom = [
    {
      normal: '4',
      chinese: '110/50'
    },
    {
      normal: '5',
      chinese: '110/53'
    },
    {
      normal: '6',
      chinese: '120/53'
    },
    {
      normal: '7',
      chinese: '120/56'
    },
    {
      normal: '8',
      chinese: '130/56'
    },
    {
      normal: '9',
      chinese: '135/59'
    },
    {
      normal: '10',
      chinese: '140/59'
    },
    {
      normal: '11',
      chinese: '145/62'
    },
    {
      normal: '12',
      chinese: '150/62'
    },
    {
      normal: '13',
      chinese: '155/65'
    },
    {
      normal: '14',
      chinese: '160/65'
    },
    {
      normal: '15',
      chinese: '165/68A'
    },
    {
      normal: '16',
      chinese: '170/68A'
    }
  ]

  boysTop = [
    {
      normal: '4',
      chinese: '110/56'
    },
    {
      normal: '6',
      chinese: '120/60'
    },
    {
      normal: '8',
      chinese: '130/64'
    },
    {
      normal: '10',
      chinese: '140/68'
    },
    {
      normal: '12',
      chinese: '150/76'
    },
    {
      normal: '14',
      chinese: '160/82'
    },
    {
      normal: '16',
      chinese: '170/86A'
    }
  ]

  boysBottom = [
    {
      normal: '4',
      chinese: '110/50'
    },
    {
      normal: '5',
      chinese: '110/53'
    },
    {
      normal: '6',
      chinese: '120/53'
    },
    {
      normal: '7',
      chinese: '120/56'
    },
    {
      normal: '8',
      chinese: '130/56'
    },
    {
      normal: '9',
      chinese: '135/59'
    },
    {
      normal: '10',
      chinese: '140/59'
    },
    {
      normal: '11',
      chinese: '145/62'
    },
    {
      normal: '12',
      chinese: '150/62'
    },
    {
      normal: '13',
      chinese: '155/65'
    },
    {
      normal: '14',
      chinese: '160/65'
    },
    {
      normal: '15',
      chinese: '165/68A'
    },
    {
      normal: '16',
      chinese: '170/68A'
    }
  ]

  unisexTop = [
    {
      normal: 'XS',
      chinese: '155/64A'
    },
    {
      normal: 'S',
      chinese: '160/68A'
    },
    {
      normal: 'M',
      chinese: '165/74A'
    },
    {
      normal: 'L',
      chinese: '170/78A'
    },
    {
      normal: 'XL',
      chinese: '175/84A'
    },
    {
      normal: 'XXL',
      chinese: '180/88A'
    },
  ]

  others = [
    {
      normal: 'Scarves',
      chinese: ''
    },
    {
      normal: 'Bonnets',
      chinese: ''
    }
  ]

  unisexBottom = [
    {
      normal: 'XS',
      chinese: '155/80A'
    },
    {
      normal: 'S',
      chinese: '160/84A'
    },
    {
      normal: 'M',
      chinese: '165/88A'
    },
    {
      normal: 'L',
      chinese: '170/92A'
    },
    {
      normal: 'XL',
      chinese: '175/100A'
    },
    {
      normal: 'XXL',
      chinese: '180/104A'
    },
  ]

  sizeTypes = [
    'WOMEN_TOP', 'WOMEN_BOTTOM', 'MEN_TOP', 'MEN_TOP_PRM' ,'MEN_BOTTOM', 'MEN_BOTTOM_PRM', 
    'GIRLS_TOP', 'GIRLS_BOTTOM', 'BOYS_TOP', 'BOYS_BOTTOM', 'UNISEX_TOP', 'UNISEX_BOTTOM', 'OTHERS'
  ]

  options: any = {
    'WOMEN_TOP': [],
    'WOMEN_BOTTOM': [], 'MEN_TOP': [], 'MEN_TOP_PRM': [] ,'MEN_BOTTOM': [], 'MEN_BOTTOM_PRM': [], 
    'GIRLS_TOP': [], 'GIRLS_BOTTOM': [], 'BOYS_TOP': [], 'BOYS_BOTTOM': [], 'UNISEX_TOP': [], 'UNISEX_BOTTOM': [], 'OTHERS': []

  }

  headers: string[] = ['Women Top', 'Women Bottom', 'Men Top', 'Men Top PRM' ,'Men Bottom', 'Men Bottom Prm', 
  'Girls Top', 'Girls Bottom', 'Boys Top', 'Boys Bottom', 'Unisex Top', 'Unisex Bottom', 'Others']

  selectedOptions:any[] = [
    [], [], [], [], [], [], [], [], [], [], [], [], []
  ];

  formControl = new FormControl(0, Validators.required);

  constructor(
    public dialogRef: MatDialogRef<ChineseLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private chineseSizeService: ChineseSizeService
  ) { }

  ngOnInit(): void {
    this.sizeTypes.forEach(item => {
      this.chineseSizeService.getByType(item).subscribe(res => {
        this.options[item] = res
      })
    })
  }

  onOkClick(){
    let sizes: any[] = []
    this.selectedOptions.forEach(list => {
      list.forEach((item: any) => {
        sizes.push(item)
      })
    })
    console.log(sizes)
    this.dialogRef.close(sizes)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
