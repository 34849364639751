<div class="work-container">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <img src="../../../assets/label.png" />
                <div class="text">Label</div>
            </div>
            <div class="col-md-3">
                <img src="../../../assets/label2.png" />
                <div class="text">Label</div>
            </div>
            <div class="col-md-3">
                <img src="../../../assets/yikama.png" />
                <div class="text">Label</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <img src="../../../assets/label.png" />
                <div class="text">Label</div>
            </div>
            <div class="col-md-3">
                <img src="../../../assets/label2.png" />
                <div class="text">Label</div>
            </div>
            <div class="col-md-3">
                <img src="../../../assets/yikama.png" />
                <div class="text">Label</div>
            </div>
        </div>
    </div>
</div>