import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { WarningMessageService } from 'src/app/@Core/service/warning-message.service';
import { Categories } from '../dto/Categories';
import { IOtherLangs, Warning } from '../dto/Warning';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent implements OnInit {
  selectedWarning: any = {
    id: null,
    type: 'WASHING',
    value: {
      gb: '',
      de: '',
      fr: '',
      es: '',
      it: '',
      pt: '',
      gr: '',
      ch: ''
    }
  };
  selectedCategory: string = '';
  languageCode: any;
  text: any;
  displayDialog: boolean = false;
  categories: string[] = ['WASHING', 'IRONING', 'DRY_CLEANING', 'EXTRA'];
  languages: string[] = ['gb', 'de', 'fr', 'es', 'it', 'pt', 'gr', 'ch'];

  warnings: any[] = [];

  areFieldsDisabled = true;
  displayDeleteDialog = false;

  constructor(private router: Router, private warningMessageService: WarningMessageService) {}
  ngOnInit(): void {

    this.warningMessageService.getAllWarnings().subscribe(res => {

      this.warnings = res;
      console.log(this.warnings)

    },
    err => {

    })

  }

  // close modal dialog
  hideDialog() {
    //clear attirbutes
    this.selectedWarning = {
      id: null,
      type: 'WASHING',
      value: {
        gb: '',
        de: '',
        fr: '',
        es: '',
        it: '',
        pt: '',
        gr: '',
        ch: ''
      }
    };

    this.displayDialog = false;
    this.areFieldsDisabled = true;
    this.displayDeleteDialog = false;
  }

  // open modal dialog
  openModalDialog(warning: any, modalType:string) {
    // set selected row's data

    if(modalType == 'edit')
      this.areFieldsDisabled = false
    this.selectedWarning = warning;

    this.displayDialog = true;
  }

  // edit selected warning row
  editWarning() {
    this.warningMessageService.saveWarning(this.selectedWarning).subscribe(() => {
      this.warningMessageService.getAllWarnings().subscribe(res => {

        this.warnings = res;

      },
      err => {

      })
      this.hideDialog()

    }, err => {
      this.hideDialog()
    })

  }

  openCreateDialog(){
    this.areFieldsDisabled = false;
    this.displayDialog = true;
  }

  openDeleteDialog(warning: any){
    this.displayDeleteDialog = true
    this.selectedWarning = warning;
  }

  // delete selected warning row
  deleteWarning() {
    this.warningMessageService.deleteWarningById(this.selectedWarning.id).subscribe(() => {
      this.warningMessageService.getAllWarnings().subscribe(res => {

        this.warnings = res;

      },
      err => {

      })
      this.hideDialog()

    }, err => {
      this.hideDialog()
    })
  }

  // show other languages table
  navigateOtherLanguages(warning: Warning) {
    this.selectedWarning = [warning.otherlangs];
    this.router.navigate(['warnings/other'], {
      state: {
        warnings:
          this.selectedWarning !== undefined ? this.selectedWarning[0] : null,
      },
    });
  }

  customSort(event: SortEvent) {
    event?.data?.sort((data1, data2) => {
      let value1 = data1[event.field!];
      let value2 = data2[event?.field!];
      let result = 0;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order! * result;
    });
  }
}
