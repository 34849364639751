<div class="example-sidenav-content">
  <div class="p-3">
    <div class="card p-3 m-3 shadow">
      <div class="row">
        <div class="col-3 p-3">
          <!-- <i class="fa fa-tag" style="font-size: 2rem"></i> -->
          <fa-icon [icon]="userIcon" style="font-size: 2rem;background-color:#337ab7;color: white;padding: 20%;border-radius: 15px;"></fa-icon>
        </div>
        <div class="col-9">
          <p style="font-size: 1.3rem;color: #32323A">{{ this.totalUserNumber }}</p>
          <p style="font-size: 13px;color: #32323A">Registered Users</p>
        </div>
      </div>
    </div>

    <div class="card p-3 m-3 shadow">
      <div class="row">
        <div class="col-3 p-3">
          <!-- <i class="fa fa-tag" style="font-size: 2rem"></i> -->
          <fa-icon [icon]="washedIcon" style="font-size: 2rem;background-color:#fa8564;color: white;padding: 20%;border-radius: 15px;"></fa-icon>
        </div>
        <div class="col-9">
          <p style="font-size: 1.3rem;color: #32323A">{{ this.totalWashedInstructionNumber }}</p>
          <p style="font-size: 13px;color: #32323A">Washed Instructions</p>
        </div>
      </div>
    </div>


    <div class="card p-3 m-3 shadow">
      <div class="row">
        <div class="col-3 p-3">
          <!-- <i class="fa fa-tag" style="font-size: 2rem"></i> -->
          <fa-icon [icon]="completedIcon" style="font-size: 2rem;background-color:#1FB5AD;color: white;padding: 20%;border-radius: 15px;"></fa-icon>
        </div>
        <div class="col-9">
          <p style="font-size: 1.3rem;color: #32323A">{{ this.totalCompletedInstructionNumber }}</p>
          <p style="font-size: 13px;color: #32323A">Completed Washing Instructions</p>
        </div>
      </div>
    </div>
  </div>

  <p-chart
    type="pie"
    [data]="data"
    [options]="chartOptions"
    [style]="{ width: '50%','padding-top':'1.7%' }"
  ></p-chart>
</div>

