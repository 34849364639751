import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningSymbolService } from 'src/app/@Core/service/warning-symbol.service';
import { Warning } from '../../dto/Warning';
import { WarningSymbol } from '../../dto/WarningSymbol';

@Component({
  selector: 'app-symbol-dialog',
  templateUrl: './symbol-dialog.component.html',
  styleUrls: ['./symbol-dialog.component.scss']
})
export class SymbolDialogComponent implements OnInit, AfterViewInit {

  washingSymbolList: WarningSymbol[] = [
    /*{
      order: 1,
      file: '../../../../assets/order/washing/wash 30-1.svg'
    },
    {
      order: 2,
      file: '../../../../assets/order/washing/wash 30-2.svg'
    },
    {
      order: 3,
      file: '../../../../assets/order/washing/wash 30.svg'
    },
    {
      order: 4,
      file: '../../../../assets/order/washing/wash 40-1.svg'
    },
    {
      order: 5,
      file: '../../../../assets/order/washing/wash 40-2.svg'
    },
    {
      order: 6,
      file: '../../../../assets/order/washing/wash 40.svg'
    },
    {
      order: 7,
      file: '../../../../assets/order/washing/wash 50-1.svg'
    },
    {
      order: 8,
      file: '../../../../assets/order/washing/wash 50.svg'
    },
    {
      order: 9,
      file: '../../../../assets/order/washing/wash 60-1.svg'
    },
    {
      order: 10,
      file: '../../../../assets/order/washing/wash 60.svg'
    },
    {
      order: 11,
      file: '../../../../assets/order/washing/wash hand.svg'
    },
    {
      order: 12,
      file: '../../../../assets/order/washing/wash not.svg'
    },
    {
      order: 13,
      file: '../../../../assets/order/washing/wash.svg'
    },*/

  ]

  bleachingSymbolList: WarningSymbol[] = [
    /*{
      order: 14,
      file: '../../../../assets/order/bleach/bleach-2.svg'
    },
    {
      order: 15,
      file: '../../../../assets/order/bleach/bleach.svg'
    },
    {
      order: 16,
      file: '../../../../assets/order/bleach/do not bleach.svg'
    },*/
  ]

  dryingSymbolList: WarningSymbol[] = [
    /*{
      order: 17,
      file: '../../../../assets/order/dry/do not tumble dry.svg'
    },
    {
      order: 18,
      file: '../../../../assets/order/dry/dry 1.svg'
    },
    {
      order: 19,
      file: '../../../../assets/order/dry/dry 2.svg'
    },
    {
      order: 20,
      file: '../../../../assets/order/dry/dry 3.svg'
    },
    {
      order: 21,
      file: '../../../../assets/order/dry/dry 4.svg'
    },
    {
      order: 22,
      file: '../../../../assets/order/dry/dry 5.svg'
    },
    {
      order: 23,
      file: '../../../../assets/order/dry/dry 6.svg'
    },
    {
      order: 24,
      file: '../../../../assets/order/dry/dry 7.svg'
    },
    {
      order: 25,
      file: '../../../../assets/order/dry/dry 8.svg'
    },
    {
      order: 26,
      file: '../../../../assets/order/dry/tumble dry low.svg'
    },
    {
      order: 27,
      file: '../../../../assets/order/dry/tumble dry medium.svg'
    },
    {
      order: 28,
      file: '../../../../assets/order/dry/tumble.svg'
    },*/
  ]

  ironSymbolList: WarningSymbol[] = [
    /*{
      order: 29,
      file: '../../../../assets/order/ironing/iron 1.svg'
    },
    {
      order: 30,
      file: '../../../../assets/order/ironing/iron 2.svg'
    },
    {
      order: 31,
      file: '../../../../assets/order/ironing/iron 3.svg'
    },
    {
      order: 32,
      file: '../../../../assets/order/ironing/iron not.svg'
    },
    {
      order: 33,
      file: '../../../../assets/order/ironing/iron.svg'
    },*/
  ]

  dryCleaningSymbolList: WarningSymbol[] = [
    /*{
      order: 34,
      file: '../../../../assets/order/drying/cleaning.svg'
    },
    {
      order: 35,
      file: '../../../../assets/order/drying/drying F.svg'
    },
    {
      order: 36,
      file: '../../../../assets/order/drying/drying F1.svg'
    },
    {
      order: 37,
      file: '../../../../assets/order/drying/drying not.svg'
    },
    {
      order: 38,
      file: '../../../../assets/order/drying/drying P.svg'
    },
    {
      order: 39,
      file: '../../../../assets/order/drying/drying P1.svg'
    },
    {
      order: 40,
      file: '../../../../assets/order/drying/drying W.svg'
    },
    {
      order: 41,
      file: '../../../../assets/order/drying/drying W1.svg'
    },
    {
      order: 42,
      file: '../../../../assets/order/drying/drying W2.svg'
    },*/
  ]

  selectedOptions: WarningSymbol[][] = [
    [], [], [], [], []
  ];

  readSelectedOptions: WarningSymbol[][] = [
    [], [], [], [], []
  ];

  constructor(
    public dialogRef: MatDialogRef<SymbolDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private symbolService: WarningSymbolService,
  ) {

    this.symbolService.getAllWashingSymbols().subscribe(res => {

      console.log(res)
      for (let i = 0; i < res.length; i++) {

        switch (res[i].type) {
          case 'WASHING':
            this.washingSymbolList.push(res[i]);
            break;
          case 'IRONING':
            this.ironSymbolList.push(res[i]);
            break;
          case 'DRYING':
            this.dryingSymbolList.push(res[i]);
            break;
          case 'DRY_CLEANING':
            this.dryCleaningSymbolList.push(res[i]);
            break;
          case 'BLEACHING':
            this.bleachingSymbolList.push(res[i]);
            break;
        }

      }

    })


    if (this.data)
      this.selectedOptions = this.data;

    this.readSelectedOptions = this.selectedOptions;  
    console.log(this.selectedOptions)
    console.log(this.data)
    



  }
  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {

    

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isSelected(symbol: WarningSymbol, index: number) {
    let array = this.selectedOptions[index].map(item => item.id)
    //console.log(array)
    //console.log(array.includes(symbol.id))
    //console.log(this.readSelectedOptions)
    //console.log(this.selectedOptions[index].includes(symbol))
    //console.log(this.selectedOptions)
    return array.includes(symbol.id)
    //return selected.length == 1;
  }

  clickEvent(symbol, index: number){

    let array = this.selectedOptions[index].map(item => item.id)
    console.log(array)
    if(array.includes(symbol.id)){
      let itemIndex = array.indexOf(symbol.id)
      console.log(itemIndex)
      this.selectedOptions[index].splice(itemIndex, 1)
    }else{
      this.selectedOptions[index].push(symbol)
    }
  }

}
