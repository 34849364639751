import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageService } from './@Core/service/language.service';
import { LanguagePipe } from './@Core/pipes/language.pipe';

export function setupTranslateServiceFactory(
  service: LanguageService): Function {
  return () => service.use('turkish');
}

@NgModule({
  providers: [
    LanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateServiceFactory,
      deps: [
        LanguageService
      ],
      multi: true
    }
  ],
  declarations: [
    LanguagePipe
  ],
  imports: [
    CommonModule
  ],
  exports:[LanguagePipe]
})
export class LanguageModule { }
