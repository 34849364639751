import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WashingInstructionService {

  constructor(private http: HttpClient) {}

  save(instruction: any) {
    return this.http.post(`${environment.baseUrl}`, instruction, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }


  getAllByStatus(status: string) {
    return this.http.get(`${environment.baseUrl}status/${status}`, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  getById(id: string): any {
    return this.http.get(`${environment.baseUrl}${id}`, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  deleteById(id: string){
    return this.http.delete(`${environment.baseUrl}${id}`, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  addCritic(id: string, critic: string) {
    return this.http.post(
      `${environment.baseUrl}${id}/critics`,
      { critic: critic },
      {
        headers: new HttpHeaders({
          token: localStorage.getItem('token')!,
        }),
      }
    );
  }

  approve(id: string) {
    return this.http.put(`${environment.baseUrl}${id}/approve`, null, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  complete(id: string) {
    return this.http.put(`${environment.baseUrl}${id}/complete`, null, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  disapprove(id: string) {
    return this.http.put(`${environment.baseUrl}${id}/disapprove`, null, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }


}
