<div class="container">
    <mat-card>
        <mat-card-title>Preview</mat-card-title>
        <mat-card-content>
            <div class="row">
                <mat-form-field appearance="fill" class="col-md-2">
                    <mat-label>Fabric Color</mat-label>
                    <mat-select [(value)]="bgColor" (selectionChange)="setTextColor()">
                        <mat-option *ngFor="let color of fabricColors" [value]="color.value">
                            {{color.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-md-2">
                    <mat-label>Select Warning</mat-label>
                    <mat-select [(value)]="selectedWarningLabel" [formControl]="warningLabelList" multiple>
                        <mat-select-trigger>
                            Selected Labels: {{warningLabelList.value?.length || ''}}
                            <!--<span *ngIf="(warningLabelList.value?.length || 0) > 1"
                                class="example-additional-selection">
                                (+{{(warningLabelList.value?.length || 0) - 1}} {{warningLabelList.value?.length === 2 ?
                                'other' : 'others'}})
                            </span>-->
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of warningLabels" [value]="item">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-2" appearance="fill">
                    <mat-label>Product Category</mat-label>
                    <mat-select [(value)]="productCategory">
                        <mat-option *ngFor="let category of productCategories" [value]="category">
                            {{category}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-2" appearance="fill">
                    <mat-label>Product Group Type</mat-label>
                    <mat-select [(value)]="productGroupType">
                        <mat-option *ngFor="let type of productGroupTypes" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-md-2">
                    <mat-label>Quantity</mat-label>
                    <input [(ngModel)]="quantity" matInput required type="number">
                </mat-form-field>
                
                
                
            </div>
            <!--<div>
                <mat-checkbox class="example-margin" [(ngModel)]="isRussianActive">Add Russian</mat-checkbox>
                <mat-form-field *ngIf="isRussianActive">
                    <mat-label>Russian Quantity</mat-label>
                    <input [disabled]="!isRussianActive" [(ngModel)]="russianQuantity" matInput required
                        type="number">
                </mat-form-field>

            </div>-->

            <div>
                <button style="margin-top: 0%;" mat-stroked-button (click)="selectChineseSize()" color="primary">Add
                    Chinese</button>

            </div>
            
            <div class="row" [ngStyle]="{'transform': scaleView() }">
                <div class="instruction" [ngStyle]="{'background-color': bgColor}">
                    <div [ngStyle]="{'filter': bgColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                        <div class="label-number"></div>
                        <div class="scissor row">
                            <object id="scissor-img" data="../../../../assets/order/cutline.svg"></object>
                        </div>
                        <div>
                            <div
                                [ngStyle]="{'margin-right': originRightMargin+'mm', 'margin-left': originLeftMargin+'mm', 'font-size':originTextSize+'pt'}">
                                {{productCategory}}
                            </div>
                        </div>
                        <div class="origin" (click)="onOriginClicked()">
                            <div class="area-text" *ngIf="!isOriginSelected">
                                Select Country
                            </div>
                            <div *ngIf="isOriginSelected"
                                [ngStyle]="{'margin-right': originRightMargin+'mm', 'margin-left': originLeftMargin+'mm', 'font-size':originTextSize+'pt'}">
                                {{originValue}}
                            </div>
                        </div>
                        <div id="po-number" [ngStyle]="{'margin-top': productCategory.length > 0 ? '1.5cm': '2cm'}">
                            <input id="po-input" [(ngModel)]="poNumber" (ngModelChange)="poNumberChanged()" matInput
                                placeholder="PO Number">
                        </div>
                        <div class="symbols row origin" (click)="onSymbolsClicked()">
                            <div *ngFor="let symbol of symbolsShown">
                                <img class="symbol-img" src="{{symbol.filePath}}" />
                            </div>
                            <div *ngIf="symbolsShown.length == 0">
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/334df2a5-washing.png" />
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/c04e4d09-bleaching.png" />
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/d3d39590-drying.png" />
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/514e53db-ironing.png" />
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/5c25bdee-drycleaning.png" />
                            </div>

                        </div>
                        <div class="clevercare-logo">
                            <img id="logo-image" src="https://v5.efelabel.net/public/images/clever-black.png" />
                        </div>
                        <div *ngIf="!productGroupType || productGroupType == 'Default'" class="address">
                            Scotch & Soda B.V. Keizersgracht 105 1015CH Amsterdam The Netherlands scotch-soda.com
                        </div>
                        <div *ngIf="productGroupType && productGroupType !== 'Default'" class="address">
                            Scotch & Soda Keizersgracht 22 1015 CR Amsterdam The Netherlands {{website}}
                        </div>
                    </div>
                </div>
                <div class="instruction fabric-ratio" [ngStyle]="{'background-color': bgColor}">
                    <div [ngStyle]="{'filter': bgColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                        <div class="fabric" (click)="onFabricOptionsClicked()" #fabricDiv
                            [ngStyle]="{'margin-right': '0mm', 'margin-left': '0mm', 'font-size':fabricTextSize+'pt', 'line-height':fabricTextSize+'pt'}">
                            <div *ngIf="isFabricSelected">
                                <div *ngFor="let lang of fabricLanguages" class="fabric-row row">
                                    <div class="col-md-2">
                                        {{lang}}
                                    </div>
                                    <div class="col-md-10">
                                        <div *ngFor="let fabric of fabricOptions" style="margin-left: -6%; ; width: 100%;"
                                            [ngStyle]="{'line-height':fabricTextSize+'pt'}">
                                            {{fabric.productPart.value[lang.toLowerCase()]}}&nbsp;
                                            <span *ngFor="let feature of fabric.features" >
                                                {{feature.ratio}}%&nbsp;{{feature.value.value[lang.toLowerCase()]}}&nbsp;
                                            </span>

                                        </div>

                                    </div>

                                </div>
                                <!--<div class="fabric-row row">
                                    <div class="col-md-2">
                                        RU
                                    </div>
                                    <div class="col-md-10">
                                        <div *ngFor="let fabric of rusFabricOptions"
                                            style="margin-left: -4%;">
                                            {{fabric.productPart.RU}}&nbsp;
                                            <span *ngFor="let feature of fabric.features">
                                                %{{feature.ratio}}&nbsp;{{feature.name.RU}}&nbsp;
                                            </span>

                                        </div>

                                    </div>

                                </div>-->
                            </div>
                            <div *ngIf="!isFabricSelected" class="area-text">See Fabric Options</div>

                        </div>

                    </div>
                </div>
                <div class="instruction" [ngStyle]="{'background-color': bgColor}">
                    <div [ngStyle]="{'filter': bgColor === '#000000' ? 'invert(1)' : 'invert(0)'}">

                        <div class="cut-margin"></div>
                        <div class="label-number">{{serial}}</div>

                        <div class="scissor row">
                            <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                        </div>
                        <div class="fabric" (click)="onWashingInstructionsClicked()" #instructionDiv
                            style="padding-left: 2mm; overflow: hidden;"
                            [ngStyle]="{'margin-right': '0mm', 'margin-left': '0mm', 'font-size':instructionTextSize+'pt'}">
                            <div *ngIf="isInstructionsSelected">
                                <div *ngFor="let lang of instructionLanguages" class="fabric-row row"
                                    style="margin-top: 0%; padding-top: 0%;">
                                    <div class="col-md-2" style="margin-top: -1%;">
                                        {{lang}}
                                    </div>
                                    <div class="col-md-10">
                                        <div *ngFor="let instruction of instructions"
                                            [ngStyle]="{'lineHeight': (instructionTextSize)+'pt'}"
                                            style=" margin-left: -4%;">
                                            {{instruction.value[lang.toLowerCase()]}}&nbsp;

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div *ngIf="!isInstructionsSelected" class="area-text">Select Washing Instructions</div>

                        </div>
                    </div>
                </div>
                <div class="instruction" *ngFor="let size of chineseSizes" [ngStyle]="{'background-color': bgColor}">
                    <div [ngStyle]="{'filter': bgColor === '#000000' ? 'invert(1)' : 'invert(0)'}">

                        <div class="cut-margin"></div>
                        <div class="row">

                            <div class="col-md-8"></div>
                            <div class="col-md-4 label-number" style="text-align:right; padding-right: 6%;">
                                {{size.size.type != 'OTHERS' ? size.size.normal : ''}}
                            </div>

                        </div>

                        <div class="scissor row">
                            <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                        </div>
                        <div class="chinese-label-info">
                            <div>
                                品牌:
                            </div>
                            <div>
                                <img src="../../../../assets/order/scotch_soda_logo.svg" width="144" height="30" />
                            </div>
                        </div>
                        <div class="chinese-label-info">
                            <div>
                                款号:
                            </div>
                            <div style="line-height: 12pt">
                                {{poNumber}}
                            </div>
                        </div>
                        <div class="chinese-label-info">
                            <div style="line-height: 12pt">
                                号型 / 规格:
                            </div>
                            <div>
                                {{size.size.chinese}}
                            </div>
                        </div>
                        <div class="chinese-label-info">
                            <div style="line-height: 12pt">
                                产地: {{chineseOrigin}}
                            </div>
                        </div>
                        <div class="chinese-label-info">
                            <div style="line-height: 12pt">
                                成分:
                            </div>
                            <div *ngIf="chineseFabricOptions.length == 0">
                                <div *ngFor="let fabric of fabricOptions" style="line-height: 12pt;">
                                    {{fabric.productPart.value.ch}}:&nbsp;
                                    <span *ngFor="let feature of fabric.features">
                                        {{feature.ratio}}%&nbsp;{{feature.value.value.ch}}&nbsp;
                                    </span>
    
                                </div>
                            </div>
                            <div *ngIf="chineseFabricOptions.length > 0">
                                <div *ngFor="let fabric of chineseFabricOptions" style="line-height: 12pt;">
                                    {{fabric.productPart.value.ch}}:&nbsp;
                                    <span *ngFor="let feature of fabric.features">
                                        {{feature.ratio}}%&nbsp;{{feature.value.value.ch}}&nbsp;
                                    </span>
    
                                </div>
                            </div>
                            
                        </div>
                        <div *ngIf="isInstructionsSelected">
                            <div class="row" style="padding: 4%; margin-top: 4%;">
                                <div class="col-md-3" style="font-size: 10pt; padding-right: 0%;">
                                    中文:
                                </div>
                                <div class="col-md-9">
                                    <div *ngFor="let instruction of instructions"
                                        style="font-size: 10pt; margin-left: -14%; line-height: 13pt;">
                                        {{instruction.value.ch}}&nbsp;

                                    </div>

                                </div>

                            </div>
                            <div
                                style="font-size: 10pt; text-align: center; line-height: 12pt; position: absolute; top: 20; left: 1; width: 64mm; height: 20mm; margin-top: 1%;">
                                以本洗水标信息为准
                            </div>
                            <div class="symbols row origin">
                                <div *ngFor="let symbol of symbolsShown">
                                    <img class="symbol-img" src="{{symbol.filePath}}" />
                                </div>
                                <div *ngIf="symbolsShown.length == 0">
                                    <img class="symbol-img"
                                        src="https://v5.efelabel.net/public/images/symbols/334df2a5-washing.png" />
                                    <img class="symbol-img"
                                        src="https://v5.efelabel.net/public/images/symbols/c04e4d09-bleaching.png" />
                                    <img class="symbol-img"
                                        src="https://v5.efelabel.net/public/images/symbols/d3d39590-drying.png" />
                                    <img class="symbol-img"
                                        src="https://v5.efelabel.net/public/images/symbols/514e53db-ironing.png" />
                                    <img class="symbol-img"
                                        src="https://v5.efelabel.net/public/images/symbols/5c25bdee-drycleaning.png" />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="instruction rus-date" [ngStyle]="{'background-color': bgColor}" *ngIf="isRussianActive">
                    <div [ngStyle]="{'filter': bgColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                        <div class="cut-margin"></div>
                        <div class="label-number">{{serial}}</div>
                        <div class="scissor row">
                            <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                        </div>

                        <div style="margin-top: 24%;">Дата производства</div>
                        <input [(ngModel)]="russianDate" style="margin-bottom: 10%;" matInput placeholder="dd-mm-yy">
                        <div>Наименование товара</div>
                        <div class="origin" (click)="onRussianTypeClicked()" style="margin-top: 0%;">
                            <div class="area-text" *ngIf="!isRussianTypeSelected">
                                Select Russian Product Type
                            </div>
                            <div *ngIf="isRussianTypeSelected" style="text-align: center;">
                                {{russianType.value}}
                            </div>
                        </div>
                        <div
                            style="margin-bottom: 10%;  margin-top: 1cm; width: 100%; text-align: center; padding: 10%;">
                            {{originObj.value.ru}}</div>
                        <div *ngIf="rusFabricOptions.length > 0">
                            <div *ngFor="let fabric of rusFabricOptions"
                                style="width: 100%; text-align: center; padding: 10%; padding-top: 6%;"
                                [ngStyle]="{'line-height':'12pt'}">
                                {{fabric.productPart.value['ru']}}&nbsp;
                                <span *ngFor="let feature of fabric.features">
                                    {{feature.ratio}}%&nbsp;{{feature.value.value['ru']}}&nbsp;
                                </span>

                            </div>
                        </div>

                        <div *ngIf="rusFabricOptions.length == 0">
                            <div *ngFor="let fabric of fabricOptions"
                                style="width: 100%; text-align: center;  padding-left: 10%; padding-right: 10%;"
                                [ngStyle]="{'line-height':'12pt'}">
                                {{fabric.productPart.value['ru']}}&nbsp;
                                <span *ngFor="let feature of fabric.features">
                                    {{feature.ratio}}%&nbsp;{{feature.value.value['ru']}}&nbsp;
                                </span>

                            </div>

                        </div>
                    </div>
                </div>
                <div *ngFor="let warningLabel of warningLabelList.value">
                    <div style="margin: 10px;"
                        [ngStyle]="{'background-color': (warningLabel.name === 'Animal Parts' || warningLabel.name === 'Indigo Garment') ? bgColor : '#FFF'}">
                        <div
                            [ngStyle]="{'filter': bgColor === '#000000' && (warningLabel.name === 'Animal Parts' || warningLabel.name === 'Indigo Garment') ? 'invert(1)' : 'invert(0)'}">
                            <img style="height: 160mm;" src="{{warningLabel.filePath}}" />
                            <div
                                style="position: absolute; top: 100mm; left: 1mm; right: 1mm; text-align: center; width: 68mm;">
                                <textarea *ngIf="warningLabel.text.length > 0" cols="40" rows="5"
                                    style="word-break: break-word; text-align: center; width: 100%; border: none; outline: none; resize: none;"
                                    [(ngModel)]="warningLabel.text"></textarea>
                            </div>

                        </div>

                    </div>

                </div>
                <div class="instruction info" [ngStyle]="{'background-color': bgColor}">
                    <div [ngStyle]="{'filter': bgColor === '#000000' ? 'invert(1)' : 'invert(0)'}">

                        <div class="cut-margin"></div>
                        <div class="label-number"></div>
                        <div class="scissor row">
                            <img id="scissor-img" src="../../../../assets/order/cutline.svg" />
                        </div>
                        <br *ngIf="!(!this.originObj.value['il'] && this.id)">
                        <strong>
                            Trademark and<br>
                            Brand Name of the product.
                        </strong>
                        <p>
                            SCOTCH & SODA
                        </p>
                        <br *ngIf="!(!this.originObj.value['il'] && this.id)">
                        <strong>
                            Name of the Exporter
                        </strong>
                        <br *ngIf="!(!this.originObj.value['il'] && this.id)">
                        <p [innerHTML]="groupTypeAddress[productGroupType]">
                            
                        </p>
                        <br *ngIf="!(!this.originObj.value['il'] && this.id)">
                        <strong>
                            Name of the Importer
                        </strong>
                        <p>
                            Beside Trading Company, 5,<br>
                            Abu Bakr Els-Seddik Street,<br>
                            Sheraton Heleopolis<br>
                            Square 1144, Cairo,<br>
                            EGYPT
                        </p>
                        <br *ngIf="!(!this.originObj.value['il'] && this.id)">
                        <strong>
                            UK representative
                        </strong>
                        <p>
                            14 Carnaby St, Carnaby<br>
                            London W1F 9PW<br>
                            United Kingdom
                        </p>
                        <strong *ngIf="!this.originObj.value['il'] && this.id">
                            Импортер
                        </strong>
                        <p *ngIf="!this.originObj.value['il'] && this.id">
                            AO “MФК ДжамильКо”<br>
                            107031, г. Mocква,<br>
                            Tрубная площадь, д.2,<br>
                            эт.1,пом.I, ком.55
                        </p>
                        <img *ngIf="!this.originObj.value['il'] && this.id" id="eac" src="../../../../assets/eac.svg" width="60">
                    </div>
                </div>
                <div style="margin: 10px;" [ngStyle]="{'background-color': bgColor}">
                    <div [ngStyle]="{'filter': bgColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                        <img style="height: 160mm;" src="../../../../assets/order/warning-label/Recycle.svg" />

                    </div>

                </div>




            </div>
            <div class="row">

                <div
                    style="background-color: #dfdfdf; width: 6cm; border-radius: 10px; margin: 10px;">
                    <div *ngIf="chineseSizes.length > 0" style="margin: 4%"><strong>CHINESE:</strong></div>
                    <div *ngFor="let size of chineseSizes" style="font-size: 12pt; margin: 6%">
                        <strong>{{size.size.normal}}:</strong> {{size.quantity}}

                    </div>
                    <div *ngIf="chineseSizes.length > 0" style="margin: 6%"><strong>Total: </strong>{{totalChinese}}
                    </div>
                    <div *ngIf="isRussianActive" style="margin: 4%"><strong>RUSSIAN:</strong></div>
                    <div *ngIf="isRussianActive" style="margin: 6%"><strong>Quantity: </strong>{{russianQuantity}}
                    </div>
                    <div style="margin: 4%"><strong>TOTAL QUANTITY:</strong></div>
                    <div style="margin: 6%">{{quantity}}</div>
                </div>
                <!--<div class="instruction" [ngStyle]="{'background-color': bgColor}">
                    <div *ngIf="isInstructionsSelected"
                        [ngStyle]="{'filter': bgColor === '#000000' ? 'invert(1)' : 'invert(0)'}">
                        <div class="row" style="padding: 10%; margin-top: 10%;">
                            <div class="col-md-3" style="font-size: 12pt; padding-right: 0%;">
                                中文:
                            </div>
                            <div class="col-md-9">
                                <div *ngFor="let instruction of instructions"
                                    style="font-size: 12pt; margin-left: -10%; line-height: 12pt;">
                                    {{instruction.CH}}&nbsp;

                                </div>

                            </div>

                        </div>
                        <div
                            style="font-size: 12pt; text-align: center; line-height: 12pt; position: absolute; top: 20; left: 1; width: 64mm; height: 20mm; margin-top: 1%;">
                            以本洗水标信息为准
                        </div>
                        <div class="symbols row origin">
                            <div *ngFor="let symbol of symbolsShown">
                                <img class="symbol-img" src="{{symbol.file}}" />
                            </div>
                            <div *ngIf="symbolsShown.length == 0">
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/334df2a5-washing.png" />
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/c04e4d09-bleaching.png" />
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/d3d39590-drying.png" />
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/514e53db-ironing.png" />
                                <img class="symbol-img"
                                    src="https://v5.efelabel.net/public/images/symbols/5c25bdee-drycleaning.png" />
                            </div>

                        </div>
                    </div>

                </div>-->
            </div>
        </mat-card-content>
        <mat-card-footer>
            <div style="float: right; margin: 10px; margin-right: 20px;">
                <button (click)="controlInstruction()" style="width: 200px" mat-raised-button
                    color="primary">Save</button>
            </div>
        </mat-card-footer>
    </mat-card>
</div>

<p-dialog [position]="'center'" [(visible)]="displayFillDialog" header="Check" icon="pi pi-exclamation-triangle">
    <div style="white-space: pre-line">
        {{text}}
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="hideControlDialog()" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>

</p-dialog>

<p-confirmDialog acceptLabel="Save" [position]="'center'" rejectLabel="Cancel" rejectButtonStyleClass="p-button-danger"
    header="Confirmation" icon="pi pi-exclamation-triangle">