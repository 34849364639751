import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompletedWashingInstructionComponent } from './create-washing-instructions/completed-washing-instruction/completed-washing-instruction.component';
import { CreateWashingInstructionsComponent } from './create-washing-instructions/create-washing-instructions.component';
import { ListCompletedComponent } from './create-washing-instructions/list-completed/list-completed.component';
import { ListWashingInstructionsComponent } from './create-washing-instructions/list-washing-instructions/list-washing-instructions.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FabricFeatureComponent } from './fabric-feature/fabric-feature.component';
import { LoginComponent } from './login/login.component';
import { OnlineOrderComponent } from './online-order.component';
import { OriginCountryComponent } from './origin-country/origin-country.component';
import { ProductModelComponent } from './product-model/product-model.component';
import { ProductComponent } from './product/product.component';
import { UsersComponent } from './users/users.component';
import { WarningLabelComponent } from './warning-label/warning-label.component';
import { WarningSymbolsComponent } from './warning-symbols/warning-symbols.component';

import { WarningComponent } from './warning/warning.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: OnlineOrderComponent,
    children: [
      {
        path:'',
        component:ListWashingInstructionsComponent
      },
      {
        path: 'create',
        component: CreateWashingInstructionsComponent
      }
    ]
  },
  {
    path:'users',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:UsersComponent
      }
    ]
  },
  {
    path: 'dashboard',
    component: OnlineOrderComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      }
    ]
  },
  {
    path:'warnings',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:WarningComponent
      }
    ]
  },
  {
    path:'products',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:ProductComponent
      }
    ]
  },
  {
    path:'fabric-features',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:FabricFeatureComponent
      }
    ]
  },
  {
    path:'origin-country',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:OriginCountryComponent
      }
    ]
  },
  {
    path:'warning-labels',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:WarningLabelComponent
      }
    ]
  },
  {
    path:'washing-symbols',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:WarningSymbolsComponent
      }
    ]
  },
  {
    path:'product-models',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:ProductModelComponent
      }
    ]
  },
  {
    path:'washing',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:ListWashingInstructionsComponent
      }
    ]
  },
  {
    path:'washing/approved',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:CompletedWashingInstructionComponent
      }
    ]
  },
  {
    path:'washing/completed',
    component:OnlineOrderComponent,
    children:[
      {
        path:'',
        component:ListCompletedComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnlineOrderRoutingModule { }
