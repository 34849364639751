import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  images = [944, 1011, 984, 1024].map((n) => `https://picsum.photos/id/${n}/1080/500`);

  constructor() { }

  ngOnInit(): void {
  }

}
