import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductPart } from 'src/app/pages/online-order/dto/ProductPart';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {



  products:any[]=[
    {
        id:'1',
        value:{
          gb: 'Back',
          de: 'Hinter',
          fr: 'Arrière',
          es: 'Detrás',
          it: 'Dietro',
          pt: 'Trás',
          gr: 'Πίσω μέρος',
          ru: 'Задняя часть',
          ch: '后片'
        },
    },
    {
       id:'2',
       value:{

          gb: 'Back part',
          de: 'Hinterteil',
          fr: 'Partie arrière',
          es: 'Parte trasera',
          it: 'Parte de trás',
          pt: 'Parte de trás',
          gr: 'Πλάτη',
          ru: 'Спинка',
          ch: '后片'
       },
    }
]

  constructor(private http: HttpClient) {}

  getAllProducts():Observable<any>{
    return this.http.get(environment.baseUrl+'part', {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }


  saveProduct(product: ProductPart) {
    return this.http.post(environment.baseUrl+'part', product, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }

  getProductListByType(type: string) {
    return this.http.get(`${environment.baseUrl}part/${type}`, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }

  deleteProductById(id: string) {
    return this.http.delete(`${environment.baseUrl}part/${id}`, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }
}
