<div class="example-sidenav-content">
  <div class="p-3">
    <p-table #dt styleClass="p-datatable-customers" [value]="warnings" [globalFilterFields]="['value.gb','type']"
      [rows]="10" [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="row" style="width: 100%;">
            <div class="col-md-8">
              List of Warnings
            </div>
            <div class="col-md-3">
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Search" />
            </div>
            <div class="col-md-1">
              <button mat-raised-button (click)="openCreateDialog()" color="primary">Create warning</button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 50%" pSortableColumn="value.gb">Uyarı <p-sortIcon field="value.gb"></p-sortIcon>
          </th>
          <th style="width: 30%" pSortableColumn="type">Uyarı Türü <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th>Diğer diller </th>
          <th>Düzenle </th>
          <th>Sil </th>
          

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td style="padding: 0% 1%;">{{product.value.gb}}</td>
          <td style="padding: 0% 1%;">{{product.type}}</td>
          <td style="padding: 0% 1%;"><button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openModalDialog(product, 'show')"></button></td>
          <td style="padding: 0% 1%;"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openModalDialog(product, 'edit')"></button></td>
          <td style="padding: 0% 1%;"><button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openDeleteDialog(product)"></button></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No warning found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- open dialog -->
  <p-dialog [(visible)]="displayDialog" [style]="{'width':'45%'}" [closable]="false">
    <ng-template pTemplate="header">
      UYARI MESAJI
    </ng-template>
    <div class="p-fluid p-grid">
      
      <div *ngFor="let lang of languages" class="row" style="width: 100%; padding-top: 2%;">
        <label class="col-2">{{lang.toUpperCase()}}</label>
        <input class="col-10 form-control" [(ngModel)]="selectedWarning.value[lang]" disabled="{{areFieldsDisabled}}">
      </div>

      <div class="field row" style="width: 100%; padding-top: 2%;">
        <label class="col-2">Uyarı Türü</label>
        <p-dropdown class="col-10" [options]="categories" [(ngModel)]="selectedWarning.type" disabled="{{areFieldsDisabled}}">
        </p-dropdown>
      </div>

    </div>
    <ng-template pTemplate="footer">
      <p-button *ngIf="!areFieldsDisabled" label="Kaydet" (click)="editWarning()"></p-button>
      <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
        (click)="hideDialog()"></button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="displayDeleteDialog" [style]="{'width':'45%'}">
    <ng-template pTemplate="header">
      UYARI SİL
    </ng-template>
    <div class="p-fluid p-grid">
      <div class="row" style="width: 100%;">
        <div class="col-12" style="padding: 2%; width: 100%; text-align: center;">
          Uyarıyı silmek istediğinize emin misiniz?
        </div>      
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button label="Evet" (click)="deleteWarning()"></p-button>
      <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
        (click)="hideDialog()"></button>
    </ng-template>
  </p-dialog>