import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/@Core/service/user.service';
import { IUser } from '../dto/User';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  // table properties
  displayDialog: boolean = false;
  displayDeleteDialog: boolean = false;
  loading: boolean = false;
  user: IUser = {
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    userRole: null,
    company: '',
  };

  roles:any[]=[{name:'System Admin',key:'SYSTEM_ADMIN'},{name:'Kontrolcü',key:'CONTROLLER'},{name:'Kullanıcı',key:'CUSTOMER'}];

  users: any[] = [];
  constructor(private userService: UserService, private snackBar: MatSnackBar) {
    // this.customerService.getCustomersLarge().then(customers => {
    //   this.customers = customers;
    //   this.loading = false;
    //   this.customers.forEach(
    //     customer => (customer.date = new Date(customer.date?customer.date:''))
    //   );
    // });
  }

  ngOnInit(): void {

    this.userService.getAllUsers().subscribe(res => {

      this.users = res;
      console.log(res)
    })

  }

  //close dialog
  hideDialog() {
     // clean user when modal closed
    this.user = {
      id: undefined,
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      userRole: null,
      company: '',
    };
    this.displayDialog = false;
    this.displayDeleteDialog = false; 
  }

  //open dialog
  openModalDialog(user: IUser, type: string) {

    if(type == 'create'){
      this.user = {
        id: undefined,
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        userRole: null,
        company: '',
      };
    }
    console.log(user);
    // set selected row's data
    this.user = user;
    this.displayDialog = true;
  }

  // edit selected user
  editUser(){
    console.log(this.user);
    this.userService.saveUser(this.user).subscribe(() => {
      this.hideDialog()
      this.userService.getAllUsers().subscribe(res => {

        this.users = res;
        console.log(res)
      })
    },
    err => {


        this.openSnackBar('Error Occurred While Saving The User!', 'OK')


    });    
  }

  // delete selected user
  deleteUser(){
    console.log(this.user);
    this.userService.deleteById(this.user.id!).subscribe(() => {
      this.userService.getAllUsers().subscribe(res => {

        this.users = res;
        console.log(res)
        this.hideDialog()

      })
    },
    err => {


        this.openSnackBar('Error Occurred While Deleting The User!', 'OK')


    });    
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  openDeleteDialog(user:IUser){
    this.user = user;
    this.displayDeleteDialog = true;
  }

}
