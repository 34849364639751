import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WarningSymbol } from 'src/app/pages/online-order/dto/WarningSymbol';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarningSymbolService {

  washingSymbolList = [
    {
      order: 1,
      file: '../../../../assets/order/washing/wash 30-1.svg',
      washingSymbol:'WASHING'
    },
    {
      order: 2,
      file: '../../../../assets/order/washing/wash 30-2.svg',
      washingSymbol:'BLEACHING'
    },
  ]

  constructor(private http: HttpClient) {}

  getAllWashingSymbols():Observable<any>{
    return this.http.get(environment.baseUrl + 'symbol', {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }

  getAll(){
    return this.washingSymbolList;
  }

  saveWashingSymbol(washing: WarningSymbol) {
    return this.http.post(environment.baseUrl + 'symbol', washing, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }



  deleteWashingLabelById(id: string) {
    return this.http.delete(`${environment.baseUrl}symbol/${id}`, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }
}
