<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container">
    <a class="navbar-brand " style="font-size:1rem;margin-right: 8%;">
      <img src="../../../../assets/logo.png" width="90" height="48" alt=""></a>
    <!--<div class="collapse navbar-collapse" id="navbarsExample07">
      <ul class="navbar-nav ">
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color': (selectedScreen == 'Home') ? 'rgb(197, 49, 33)' : 'rgb(73, 84, 137)' }" (click)="selectPage('Home')" aria-current="page" [routerLink]="'/'">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color': (selectedScreen == 'About') ? 'rgb(197, 49, 33)' : 'rgb(73, 84, 137)' }" (click)="selectPage('About')" [routerLink]="'/about'">Who We Are</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color': (selectedScreen == 'Work') ? 'rgb(197, 49, 33)' : 'rgb(73, 84, 137)' }" (click)="selectPage('Work')" [routerLink]="'/work'">What We Do</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color': (selectedScreen == 'Mission') ? 'rgb(197, 49, 33)' : 'rgb(73, 84, 137)' }" (click)="selectPage('Mission')" [routerLink]="'/mission'">Mission & Vision</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color': (selectedScreen == 'References') ? 'rgb(197, 49, 33)' : 'rgb(73, 84, 137)' }" (click)="selectPage('Reference')" [routerLink]="'/references'">Reference</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color': (selectedScreen == 'Certificates') ? 'rgb(197, 49, 33)' : 'rgb(73, 84, 137)' }" (click)="selectPage('Certificates')" [routerLink]="'/certificates'">Certificates</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color': (selectedScreen == 'Contacts') ? 'rgb(197, 49, 33)' : 'rgb(73, 84, 137)' }" (click)="selectPage('Contact')" [routerLink]="'/contacts'">Contact</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color': (selectedScreen == 'Order') ? 'rgb(197, 49, 33)' : 'rgb(73, 84, 137)' }" (click)="selectPage('Order')" [routerLink]="'/order/login'">Online Order</a>
        </li>
      </ul>
    </div>
    <div id="lang-div">
      <mat-select [(value)]="selectedLanguage">
        <mat-option value="en">
          <span class="flag-icon flag-icon-gb flag-icon-squared"></span> English
        </mat-option>
        <mat-option value="tr">
          <span class="flag-icon flag-icon-tr flag-icon-squared"></span> Turkish
        </mat-option>
      </mat-select>
    </div>-->
  </div>
</nav>
