<div class="example-sidenav-content">
  <div class="p-3">
    <p-table #dt styleClass="p-datatable-customers" [value]="warnings" [globalFilterFields]="['name','text']"
      [rows]="10" [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="row" style="width: 100%;">
            <div class="col-md-8">
              List of Warning Labels
            </div>
            <div class="col-md-3">
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Search" />
            </div>
            <div class="col-md-1">
              <button mat-raised-button (click)="openCreateDialog()" color="primary">Create Warning Label</button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%" pSortableColumn="name">Etiket Adı <p-sortIcon field="value.gb"></p-sortIcon>
          </th>
          <th style="width: 60%" pSortableColumn="text">Etiket Kodu <p-sortIcon field="type"></p-sortIcon>
          </th>

          <th>Düzenle </th>
          <th>Sil </th>


        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td style="padding: 1%;">{{product.name}}</td>
          <td style="padding: 1%;">{{product.text}}</td>
          <td style="padding: 1%;"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openModalDialog(product, 'edit')"></button></td>
          <td style="padding: 1%;"><button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openDeleteDialog(product)"></button></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No warning found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- open dialog -->
  <p-dialog [(visible)]="displayDialog" [style]="{'width':'50%'}" [closable]="false">
    <ng-template pTemplate="header">
     ETİKET
    </ng-template>
    <div class="p-fluid p-grid">
      <div  class="row" style="width: 100%; padding-top: 2%;">
        <label class="col-2">Ürün Resmi</label>
        <img [src]="url" width="250" height="250">
        <input type="file" class="file-upload" (change)="fileSelected($event.target.files)">

      </div>
      <div  class="row" style="width: 100%; padding-top: 2%;">
        <label class="col-2">Etiket Adı-Kodu</label>
        <input class="col-10 form-control" [(ngModel)]="this.selectedWarning.name" >
      </div>

      <div  class="row" style="width: 100%; padding-top: 2%;">
        <label class="col-2">Standart metin</label>
        <textarea style="height: 130px;;" cdkAutosizeMaxRows="5" class="col-10 form-control" [(ngModel)]="this.selectedWarning.text" ></textarea>
      </div>


    </div>
    <ng-template pTemplate="footer">
      <p-button *ngIf="!areFieldsDisabled" label="Kaydet" (click)="editWarning()"></p-button>
      <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
        (click)="hideDialog()"></button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="displayDeleteDialog" [style]="{'width':'45%'}">
    <ng-template pTemplate="header">
      ETİKET SİL
    </ng-template>
    <div class="p-fluid p-grid">
      <div class="row" style="width: 100%;">
        <div class="col-12" style="padding: 2%; width: 100%; text-align: center;">
          Etiket silmek istediğinize emin misiniz?
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button label="Evet" (click)="deleteWarning()"></p-button>
      <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
        (click)="hideDialog()"></button>
    </ng-template>
  </p-dialog>
