<h1 mat-dialog-title>Select Country</h1>
<div mat-dialog-content>
    <div class="row">
        <mat-form-field class="col-md-12" appearance="fill">
            <mat-label>Country of Origin</mat-label>
            <mat-select [(value)]="data.origin" [formControl]="originForm" >
                <mat-option *ngFor="let origin of originCountries" [value]="origin">
                    {{origin}}
                </mat-option>
            </mat-select>
        </mat-form-field>


    </div>

</div>
<div mat-dialog-actions style="float: right;">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>