<div id="mission-container">
    <div class="row">
      <div id="mission" class="col-md-6">
        <div id="mission-title">Mission</div>
        Dünya standartlarında bir tesis ile kalite, hizmet, sorumluluk ilkelerini baz alarak müşteri
        memnuniyeti sağlarken bunu doğaya, insana, ve yasaya saygı duyarak yapmak.
      </div>
      <div id="vision" class="col-md-6">
        <div id="vision-title">Vision</div>
        Etiket alanında öncü bir geleceğe oynarken iş ortakları ile yaratıcı çözümler üreten, kalite ve
        hizmetiyle fark yaratan bireye, çevreye saygılı bir dünya şirketi olmak.
      </div>
    </div>
</div>

