import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { OriginCountryService } from 'src/app/@Core/service/origin-country.service';

@Component({
  selector: 'app-origin-country-dialog',
  templateUrl: './origin-country-dialog.component.html',
  styleUrls: ['./origin-country-dialog.component.scss']
})
export class OriginCountryDialogComponent implements OnInit {

  originCountries: string[] = [];

  originCountry: any;
  marginLeft: number | undefined;

  originForm: FormControl = new FormControl(null);

  constructor(
    public dialogRef: MatDialogRef<OriginCountryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private originCountryService: OriginCountryService
  ) { 

    this.originForm = new FormControl(this.data.origin);
  }

  ngOnInit(): void {

    this.originCountryService.getAllCountryNames().subscribe(res => {

      this.originCountries = res;

    })

    if(this.data.origin){
      /*let index = this.originCountries.findIndex(x => x.name === this.data.origin.name)
      this.originCountry = this.originCountries[index]
      console.log(this.originForm.value)*/

    }
    else{
      this.originCountry = {name: '', value: ''}
    }
    console.log(this.originCountry)
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
