import { Component, OnInit, Sanitizer, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SortEvent } from 'primeng/api';
import { WarningLabelService } from 'src/app/@Core/service/warning-label.service';
import { WarningLabel } from '../dto/WarningLabel';
@Component({
  selector: 'app-warning-label',
  templateUrl: './warning-label.component.html',
  styleUrls: ['./warning-label.component.scss']
})
export class WarningLabelComponent implements OnInit {
  selectedWarning: any = {
    id: null,
    name:'',
    text:'',
    value:'',

  };

  selectedCategory: string = '';
  languageCode: any;
  text: any;
  displayDialog: boolean = false;

  warnings: any[] = [];

  areFieldsDisabled = true;
  displayDeleteDialog = false;

  uploadedFile?: File = undefined;

  url: string | SafeUrl = '';
  isFileChanged: boolean = false;

  constructor(private warningService:WarningLabelService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.warningService.getAllWarningLabels().subscribe(res => this.warnings = res);
  }

  // close modal dialog
  hideDialog() {
    //clear attirbutes
    this.selectedWarning = {
      id: null,
      name:'',
      text:'',
      value:''
    };

    this.displayDialog = false;
    this.areFieldsDisabled = true;
    this.displayDeleteDialog = false;
    this.uploadedFile = undefined;
    this.isFileChanged = false;
  }

  // open modal dialog
  openModalDialog(warning: any, modalType:string) {
    // set selected row's data

    if(modalType == 'edit')
      this.areFieldsDisabled = false
    this.selectedWarning = warning;
    this.url = warning.filePath;
    console.log(this.selectedWarning)

    this.displayDialog = true;
  }

  // edit selected warning row
  editWarning() {
    console.log(this.selectedWarning)
    this.warningService.saveWarningLabel(this.selectedWarning, this.isFileChanged, this.uploadedFile).subscribe(() => {
      this.warningService.getAllWarningLabels().subscribe(res => {

        this.warnings = res;

      },
      err => {

      })
      this.hideDialog()

    }, err => {
      this.hideDialog()
    })

  }

  openCreateDialog(){
    this.areFieldsDisabled = false;
    this.displayDialog = true;
  }

  openDeleteDialog(warning: any){
    this.displayDeleteDialog = true
    this.selectedWarning = warning;
  }

  fileSelected(files: FileList){
    var reader = new FileReader();

    reader.onload = (event: any) => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
    };

    reader.onerror = (event: any) => {
      console.log("File could not be read: " + event.target.error.code);
    };

    reader.readAsDataURL(files[0]);

    this.isFileChanged = true;
    this.uploadedFile = files[0];
  }

  // delete selected warning row
  deleteWarning() {
    this.warningService.deleteWarningLabelById(this.selectedWarning.id).subscribe(() => {
      this.warningService.getAllWarningLabels().subscribe(res => {

        this.warnings = res;

      },
      err => {

      })
      this.hideDialog()

    }, err => {
      this.hideDialog()
    })
  }



  customSort(event: SortEvent) {
    event?.data?.sort((data1, data2) => {
      let value1 = data1[event.field!];
      let value2 = data2[event?.field!];
      let result = 0;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order! * result;
    });
  }

}
