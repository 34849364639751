<div id="about-container">
    <div id="about" class="row">
      <div class="col-md-6">
        <img src="../../../assets/machine.webp">
      </div>
      <div id="about-text" class="col-md-6">
        <div id="about-title">Who We Are</div>
        Efe Etiket is a family company based on label, package, desinging and production providing
        services to its multinational clients around the world. We specialize in creating highly
        advanced, experiential works in both, labeling and packaging since 2012. As the innovation
        and high quality is our culture at Efe Etiket we dedicate ourselves to our clients and ensure to
        bring high standard on each production we complete. With experts in their fields, we are
        constantly focused and working to make every brand satisfied and even more inspiring.
        Because we believe that the innovation is an ongoing process and requires constant
        research, we do not only manufacture but also support our partners with designing their
        labels and packagings. In order to do this efficiently, we first focus on the brand characteristic
        and analyze the final target to be achieved. Finally define all additional steps to create the
        most suitable product reflecting / matching brands personality which will eventually have an
        effect on increasing brand value and awareness.
      </div>

    </div>
    <div id="team" class="row">
      <div id="team-text" class="col-md-6">
        <div id="team-title">Our Team</div>
        At Efe Etiket it is our primary goal to meet our partners’ expectations and to do that
        effectively, we pay special attention to communication. With our team that manages the
        entire process from receiving the inquiry to production and delivery, we ensure that our
        customers&#39; requests are as stress free as possible. Regardless of techniques followed, there
        are two main goals we try to achieve on each and every single label produced at Efe Etiket.
        First; the label used must not affect the consumer&#39;s comfort and/or health, second; label’s
        lifetime must last as long as the product is in use. In order to achieve these goals we take all
        technical, production and finishing steps seriously to deliver the highest quality labels.
        Because we believe in business continuity and fare trade; we constantly strive for
        improvement by following the latest technologies, respecting environment, actively applying
        sustainability and recycling rules, finally by protecting our employee&#39;s and partner&#39;s rights.
      </div>
      <div class="col-md-6">
        <img src="../../../assets/alo.webp">
      </div>

    </div>
</div>

