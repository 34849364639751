import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OriginCountry } from 'src/app/pages/online-order/dto/OriginCountry';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OriginCountryService {
  //gb,fr,
  //Made in Hungary / Fabriqué en Hongrie / Feito na Hungria / Κατασκευαστηκε στην Ουγγαρια / Произведено в Венгрии / 匈牙利
 
  constructor(private http: HttpClient) {}

  getAllOrigins(): Observable<any> {
    return this.http.get(environment.baseUrl+'origin', {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  saveOrigin(origin: OriginCountry) {
    return this.http.post(environment.baseUrl+'origin', origin, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  getOriginListByName(name: string) {
    return this.http.get(`${environment.baseUrl}origin/${name}`, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  deleteOriginById(id: string) {
    return this.http.delete(`${environment.baseUrl}origin/${id}`, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  getAllCountryNames(): Observable<any> {
    return this.http.get(environment.baseUrl+'origin/name', {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  getByCountryName(name: string): Observable<any> {
    return this.http.get(environment.baseUrl+'origin/name/'+name, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

}
