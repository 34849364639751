import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tooolbar',
  templateUrl: './tooolbar.component.html',
  styleUrls: ['./tooolbar.component.scss'],
})
export class TooolbarComponent implements OnInit {
  selectedLanguage = 'en';
  selectedScreen = 'Home';

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.router.url == '/') {
      this.selectedScreen = 'Home';
    } else {
      const pageName = this.router.url.substring(1, this.router.url.length + 1);
      console.log(this.router.url.substring(1, this.router.url.length + 1));
      this.selectedScreen =pageName.charAt(0).toUpperCase() + pageName.slice(1);
    }
  }

  selectPage(page: string) {}
}
