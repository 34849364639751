import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WarningLabel } from 'src/app/pages/online-order/dto/WarningLabel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarningLabelService {

  constructor(private http: HttpClient) {}

  getAllWarningLabels():Observable<any>{
    return this.http.get(environment.baseUrl+'label', {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }

  saveWarningLabel(warning: WarningLabel, process: boolean, file?: File) {
    let params = new HttpParams();
    params.append('process', process);

    let formData = new FormData();
    if(file){
      formData.append('file', file)
    }
    if(warning.id){
      formData.append('id', warning.id)
    }
    formData.append('name', warning.name!);
    formData.append('filePath', warning.filePath!);
    formData.append('text', warning.text!)

    return this.http.post(environment.baseUrl+'label?process='+process, formData, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
      //params: params,
      
    });
  }



  deleteWarningLabelById(id: string) {
    return this.http.delete(`${environment.baseUrl}label/${id}`, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')!,
      }),
    });
  }
}
