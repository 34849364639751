<footer class="main-footer" *ngIf="this.selectedScreen !== 'Order'">
  <div class="bg-gray-100 text-dark-700">
    <div class="py-6 bg-gray-300 text-muted">
      <div class="container">
        <div class="row" style="margin-top: 2%">
          <!--brand-->
          <div class="col-12 col-lg-6 col-md-12 col-sm-12">
            <a class="navbar-brand" style="font-size: 1rem">
              <img
                src="../../../assets/logo.png"
                width="100"
                height="50"
                alt=""
            /></a>
          </div>
          <!--list items
            <div class="col-12 col-lg-4 col-md-12 col-sm-12 m-auto text-center">
              <ul class="list-unstyled">
                <li class=" m-1"><a class="text-dark" [routerLink]="'/anasayfa'"><span style="font-size: 0.9rem;">Anasayfa</span></a></li>
                <li class=" m-1"><a class="text-dark" [routerLink]="'/hakkimizda'"><span style="font-size: 0.9rem;">Hakkımızda</span></a></li>
                <li class=" m-1"><a class="text-dark" [routerLink]="'/organizasyonlarımız'"><span style="font-size: 0.9rem;">Organizasyonlarımız</span></a></li>
                <li class=" m-1"><a class="text-dark" [routerLink]="'/iletisim'"><span style="font-size: 0.9rem;">İletişim</span></a></li>
              </ul>
            </div>
            social media-->
          <div class="col-12 col-lg-6 col-md-12 col-sm-12" style="float: right">
            <div style="float: right; width: 32%; padding: 1%">
              <a href="#" target="blank" style="padding: 2%">
                <fa-icon [icon]="['fab', 'facebook']" size="3x"></fa-icon>
              </a>
              <a href="#" target="blank" style="padding: 2%">
                <fa-icon [icon]="['fab', 'instagram']" size="3x"></fa-icon
              ></a>
              <a href="#" target="blank" style="padding: 2%">
                <fa-icon [icon]="['fab', 'whatsapp']" size="3x"></fa-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--© 2021 Your company. All rights reserved.-->
    <div class="py-4 font-weight-light bg-gray-600 text-gray-400">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 text-center text-md-center">
            <p class="mb-md-0">© 2022 Efe Label. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
