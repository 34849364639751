<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Washing">
        <mat-selection-list >
            <mat-list-option *ngFor="let symbol of washingSymbolList" 
                [value]="symbol"
                [selected]="isSelected(symbol, 0)"
                (click)="clickEvent(symbol, 0)"
                style="display:inline-block; width:33%; text-align:center;" 
                checkboxPosition="before">
                <img src="{{symbol.filePath}}"/>
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
    <mat-tab label="Bleaching">
        <mat-selection-list>
            <mat-list-option *ngFor="let symbol of bleachingSymbolList" 
                [value]="symbol"
                [selected]="isSelected(symbol, 1)"
                (click)="clickEvent(symbol, 1)"
                style="display:inline-block; width:33%; text-align:center;" 
                checkboxPosition="before">
                <img src="{{symbol.filePath}}"/>
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
    <mat-tab label="Drying">
        <mat-selection-list >
            <mat-list-option *ngFor="let symbol of dryingSymbolList" 
                [value]="symbol"
                [selected]="isSelected(symbol, 2)"
                (click)="clickEvent(symbol, 2)"
                style="display:inline-block; width:33%; text-align:center;" 
                checkboxPosition="before">
                <img src="{{symbol.filePath}}"/>
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
    <mat-tab label="Ironing">
        <mat-selection-list >
            <mat-list-option *ngFor="let symbol of ironSymbolList" 
                [value]="symbol"
                [selected]="isSelected(symbol, 3)"
                (click)="clickEvent(symbol, 3)"
                style="display:inline-block; width:33%; text-align:center;" 
                checkboxPosition="before">
                <img src="{{symbol.filePath}}"/>
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
    <mat-tab label="Dry Cleaning">
        <mat-selection-list >
            <mat-list-option *ngFor="let symbol of dryCleaningSymbolList" 
                [value]="symbol"
                [selected]="isSelected(symbol, 4)"
                (click)="clickEvent(symbol, 4)"
                style="display:inline-block; width:33%; text-align:center;" 
                checkboxPosition="before">
                <img src="{{symbol.filePath}}"/>
            </mat-list-option>
        </mat-selection-list>
    </mat-tab>
</mat-tab-group>
<div mat-dialog-actions style="float: right;">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="selectedOptions" cdkFocusInitial>Ok</button>
</div>
