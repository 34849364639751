import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from 'src/app/@Core/service/user.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('',[Validators.required]),
  });


  constructor(private router:Router, private userService: UserService, private snackBar: MatSnackBar) { }
  ngOnInit(): void {

  }


  login(username: string, password: string) {

    if (this.form.valid) {
      
      this.userService.login(username, password).subscribe((res: ({token: string})) => {

        console.log(res)
        localStorage.setItem('token', res.token.toString())
        this.router.navigate(['']);

      },
      (err: any)=>{

        if(err.status == 404)
        this.openSnackBar('Please Check Your Username Or Password', 'OK')
        else
          this.openSnackBar(err.message, 'OK')

      })

    }

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

}
