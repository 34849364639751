import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RussianProductTypeService } from 'src/app/@Core/service/russian-product-type.service';

@Component({
  selector: 'app-russian-type-dialog',
  templateUrl: './russian-type-dialog.component.html',
  styleUrls: ['./russian-type-dialog.component.scss']
})
export class RussianTypeDialogComponent implements OnInit {

  types: string[] = [];

  selectedType: any;

  constructor(
    public dialogRef: MatDialogRef<RussianTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productTypeService: RussianProductTypeService
  ) { }

  ngOnInit(): void {

    this.productTypeService.getAll().subscribe(res => {

      console.log(res)
      this.types = res

    })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
