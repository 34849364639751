import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from 'src/app/@Core/service/user.service';

@Component({
  selector: 'app-online-order',
  templateUrl: './online-order.component.html',
  styleUrls: ['./online-order.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class OnlineOrderComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav | undefined;
  display: boolean = true;
  username: string | null = '';


  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) { }
  ngOnInit() {

    this.userService.getCurrent().subscribe((res: { userRole: string; }) => {

      if (res.userRole == "SYSTEM_ADMIN") {

        this.panelContentItems = [
          {
            label: 'Washing Instructions',
            icon: 'pi pi-pw pi-plus',
            items: [
              { label: 'List Washing Instructions', routerLink: ['/washing'] },
              { separator: true },
              { label: 'Completed Washing Instruction', routerLink: ['/washing/approved'] },
              { separator: true },
              { label: 'Completed Washing (Print)', routerLink: ['/washing/completed'] },
              { separator: true },
              { label: 'Create Washing Instructions', routerLink: ['/create'] },
            ],
          },
          {
            label: 'Uyarı Yazıları',
            icon: 'pi pi-pw pi-file',
            routerLink: ['/warnings']
            /*items: [
              { label: 'Uyarı Listesi',routerLink: ['/warnings'] },
              { separator: true },
              { label: 'Uyarı Listesi Ekle',routerLink: ['/warnings'] },
            ],*/
          },
          {
            label: 'Ürün Parçaları',
            icon: 'pi pi-pw pi-sitemap',
            routerLink: ['/products']
            /*items: [
              { label: 'Ürün Parçası Listesi',routerLink: ['/products'] },
              { separator: true },
              { label: 'Ürün Parçası Ekle' },
            ],*/
          },
          {
            label: 'Kumaş Özellikleri',
            icon: 'pi pi-pw pi-star',
            routerLink: ['/fabric-features']
            /*items: [
              { label: 'Kumaş özellik listesi',routerLink: ['/fabric-features'] },
              { separator: true },
              { label: 'Kumaş özelliği ekle' },
            ],*/
          },
          {
            label: 'Üretim yeri',
            icon: 'pi pi-pw pi-cog',
            routerLink: ['/origin-country'],
            /*items: [
              { label: 'Üretim Yeri listesi',routerLink: ['/origin-country'] },
              { separator: true },
              { label: 'Üretim Yeri Ekle' },
            ],*/
          },
          {
            label: 'Uyarı Etiketleri',
            icon: 'pi pi-pw pi-file',
            routerLink: ['/warning-labels']
            /*items: [
              { label: 'Uyarı Etiket listesi',routerLink: ['/warning-labels'] },
              { separator: true },
              { label: 'Uyarı Etiketi ekle' },
            ],*/
          },
          {
            label: 'Uyarı Sembolleri',
            icon: 'pi pi-pw pi-info',
            routerLink: ['/washing-symbols']
            /*items: [
              { label: 'Uyarı Sembol listesi',routerLink: ['/washing-symbols']  },
              { separator: true },
              { label: 'Uyarı Sembolü ekle' },
            ],*/
          },
          {
            label: 'Ürün Modelleri(RU)',
            icon: 'pi pi-pw pi-book',
            routerLink: ['/product-models'],
            /*items: [
              { label: 'Ürün Modal Listesi',routerLink: ['/product-models'] },
              { separator: true },
              { label: 'Uyarı Sembolü ekle' },
            ],*/
          },
          {
            label: 'Kullanıcı Kayıtları',
            icon: 'pi pi-pw pi-users',
            routerLink: ['/users'],
            /*items: [
              { label: 'Kullanıcı Listesi', routerLink: ['/users']},
              { separator: true },

            ],*/
          },
          /*{
            label: 'Logout',
            icon: 'pi pi-pw pi-key',

          },*/
        ]
      }

    })
    // this.username=this.route.snapshot.paramMap.get('username');
    // this.customerService.getCustomersLarge().then(customers => {
    //   this.customers = customers;
    //   this.loading = false;

    //   this.customers.forEach(
    //     customer => (customer.date = new Date(customer.date?customer.date:''))
    //   );
    // });
  }

  panelContentItems: any[] = [
    //{
      /*label: 'Washing Instructions',
      icon: 'pi pi-pw pi-plus',
      items: [*/
        { label: 'List Washing Instructions', routerLink: ['/washing'] },
        { label: 'Completed Washing Instruction', routerLink: ['/washing/approved'] },
        { label: 'Completed Washing (Print)', routerLink: ['/washing/completed'] },
        { label: 'Create Washing Instructions', routerLink: ['/create'] },
      //],
    //}
  ];

  onSideBarToggle() {
    this.display = !this.display;
  }

  onLogout() {
    this.router.navigate(['login']);
  }
  onProfile() { }


}
