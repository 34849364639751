<div class="example-sidenav-content">
  <div class="p-3">
    <p-table #dt styleClass="p-datatable-customers" [value]="products" [globalFilterFields]="['name','value']"
      [rows]="10" [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="row" style="width: 100%;">
            <div class="col-md-8">
              List Of Products Models
            </div>
            <div class="col-md-3">
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Search" />
            </div>
            <div class="col-md-1">
              <button mat-raised-button (click)="openCreateDialog()" color="primary">Create product model</button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>

          <th style="width: 40%" pSortableColumn="name">Ürün Parçası <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th style="width: 40%" pSortableColumn="value">Rusça <p-sortIcon field="type"></p-sortIcon></th>
          <th style="width: 10%">Düzenle </th>
          <th style="width: 10%">Sil </th>


        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td style="padding: 0% 1%;">{{product?.name}}</td>
          <td style="padding: 0% 1%;">{{product?.value}}</td>
          <td style="padding: 0% 1%;"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openModalDialog(product, 'edit')"></button></td>
          <td style="padding: 0% 1%;"><button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-secondary p-button-text"
              (click)="openDeleteDialog(product)"></button></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No Products found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- open dialog -->
  <p-dialog [(visible)]="displayDialog" [style]="{'width':'45%'}" [closable]="false">
    <ng-template pTemplate="header">
      ÜRÜN MODELINI DÜZENLE
    </ng-template>
    <div class="p-fluid p-grid">

      <div  class="row" style="width: 100%; padding-top: 2%;">
        <label class="col-4">Ürün Model Adı</label>
        <input class="col-8 form-control" [(ngModel)]="selectedProduct.name" disabled="{{areFieldsDisabled}}">
      </div>
      <div  class="row" style="width: 100%; padding-top: 2%;">
        <label class="col-4">Ürün Model Rusça Dili</label>
        <input class="col-8 form-control" [(ngModel)]="selectedProduct.value" disabled="{{areFieldsDisabled}}">
      </div>


    </div>
    <ng-template pTemplate="footer">
      <p-button *ngIf="!areFieldsDisabled" label="Kaydet" (click)="editProduct()"></p-button>
      <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
        (click)="hideDialog()"></button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="displayDeleteDialog" [style]="{'width':'45%'}">
    <ng-template pTemplate="header">
      ÜRÜN PARÇASI SİL
    </ng-template>
    <div class="p-fluid p-grid">
      <div class="row" style="width: 100%;">
        <div class="col-12" style="padding: 2%; width: 100%; text-align: center;">
          Ürünü silmek istediğinize emin misiniz?
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button label="Evet" (click)="deleteProduct()"></p-button>
      <button pButton pRipple type="button" label="İptal" class="p-button-raised p-button-text p-button-plain"
        (click)="hideDialog()"></button>
    </ng-template>
  </p-dialog>

