import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { OriginCountryService } from 'src/app/@Core/service/origin-country.service';

@Component({
  selector: 'app-origin-country',
  templateUrl: './origin-country.component.html',
  styleUrls: ['./origin-country.component.scss']
})
export class OriginCountryComponent implements OnInit {
  displayDialog: boolean = false;
  selectedOrigin: any = {
    id: null,
    name:'',
    value: {
      gb: '',
      de: '',
      fr: '',
      es: '',
      it: '',
      pt: '',
      gr: '',
      ch: '',
      ua: '',
      il: ''
    },
  };

  areFieldsDisabled = true;
  displayDeleteDialog = false;

  origins: any[] = [];
  languages: string[] = ['gb', 'de', 'fr', 'es', 'it', 'pt', 'gr', 'ru', 'ch', 'ua', 'il'];

  constructor(private originService:OriginCountryService) { }




  ngOnInit(): void {
    // this.originService.getAllOrigins().subscribe(
    //   (res) => {
    //     this.origins = res;
    //   },
    //   (err) => {}
    // );

    this.originService.getAllOrigins().subscribe(res => this.origins = res);
  }

  // close modal dialog
  hideDialog() {
    //clear attirbutes
    this.selectedOrigin = {
      id: null,
      name: '',
      value: {
        gb: '',
        de: '',
        fr: '',
        es: '',
        it: '',
        pt: '',
        gr: '',
        ch: '',
        ua: '',
        il: ''
      },
    };

    this.displayDialog = false;
    this.areFieldsDisabled = true;
    this.displayDeleteDialog = false;
  }

  // open modal dialog
  openModalDialog(product: any, modalType: string) {
    // set selected row's data

    if (modalType == 'edit') this.areFieldsDisabled = false;
    this.selectedOrigin = product;

    this.displayDialog = true;
  }

  editOrigin() {
    this.originService.saveOrigin(this.selectedOrigin).subscribe(
      () => {
        this.originService.getAllOrigins().subscribe(
          (res) => {
            this.origins = res;
            this.hideDialog();
          },
          (err) => {
            this.hideDialog();
          }
        );
      },
      (err) => {
        this.hideDialog();
      }
    );
  }

  openCreateDialog() {
    this.areFieldsDisabled = false;
    this.displayDialog = true;
  }

  openDeleteDialog(product: any) {
    this.displayDeleteDialog = true;
    this.origins = product;
  }

  deleteOrigin() {
    this.originService.deleteOriginById(this.selectedOrigin?.id).subscribe(
      () => {
        this.originService.getAllOrigins().subscribe(
          (res) => {
            this.origins = res;
          },
          (err) => {}
        );
        this.hideDialog();
      },
      (err) => {}
    );
    this.hideDialog();
  }

  //  // show other products table
  //  navigateOtherProducts(product: any) {
  //   this.selectedWarning = [warning.otherlangs];
  //   this.router.navigate(['warnings/other'], {
  //     state: {
  //       warnings:
  //         this.selectedWarning !== undefined ? this.selectedWarning[0] : null,
  //     },
  //   });
  // }

  customSort(event: SortEvent) {
    event?.data?.sort((data1, data2) => {
      let value1 = data1[event.field!];
      let value2 = data2[event?.field!];
      let result = 0;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order! * result;
    });
  }

}
