import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { AboutComponent } from './pages/about/about.component';
import { CertificatesComponent } from './pages/certificates/certificates.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { MissionComponent } from './pages/mission/mission.component';
import { ReferenceComponent } from './pages/reference/reference.component';
import { WorkComponent } from './pages/work/work.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/online-order/online-order.module').then(
        (m) => m.OnlineOrderModule
      ),
  },
  /*{
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: '', component: HomepageComponent },
      { path: 'home', component: HomepageComponent },
    ],
  },
  {
    path: 'about',
    component: DefaultLayoutComponent,
    children: [{ path: '', component: AboutComponent }],
  },
  {
    path: 'work',
    component: DefaultLayoutComponent,
    children: [{ path: '', component: WorkComponent }],
  },
  {
    path: 'mission',
    component: DefaultLayoutComponent,
    children: [{ path: '', component: MissionComponent }],
  },
  {
    path: 'references',
    component: DefaultLayoutComponent,
    children: [{ path: '', component: ReferenceComponent }],
  },
  {
    path: 'certificates',
    component: DefaultLayoutComponent,
    children: [{ path: '', component: CertificatesComponent }],
  },
  {
    path: 'contacts',
    component: DefaultLayoutComponent,
    children: [{ path: '', component: ContactsComponent }],
  },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
