<div class="work-container">
    <div class="title">OUR REFERENCES</div>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="text">KİP</div>
            </div>
            <div class="col-md-4">
                <div class="text">RAMSEY</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="text">BOOHOO</div>
            </div>
            <div class="col-md-4">
                <div class="text">SCOTCH & SODA</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="text">CASESTUDY HK</div>
            </div>
            <div class="col-md-4">
                <div class="text">PRIMEX</div>
            </div>
        </div>
    </div>
</div>
