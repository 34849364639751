<div class="contacts-container">
    <mat-card class="card">
        
        <div class="row">

            <div class="col-md-5">
                <mat-card-title>Inquiries</mat-card-title>
                <mat-card-content>
                    <br/>
                    <p>For any inquiries, questions or recommendation,<br/>
                        please call +90 (212) 577 46 40 or fill out following form</p>
                    <div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Name"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <br/>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="E-Mail"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <br/>
                      <div>
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            placeholder="Message"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button>SEND</button>
                </mat-card-actions>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <mat-card-title>Head Office</mat-card-title>
                <p>Maltepe Mah. Davutpaşa Cd. Emintaş Mat. Sit.<br/>
                    No: 101/175-176<br/>
                    Topkapı, Zeytinburnu / İstanbul / TURKEY
                    <br/>
                </p>
                    TEL. +90 (212) 577 46 40
                    <br/>
                    info@efeetiket.net
                    <br/>
                    <br/>
                <img src="http://www.efeetiket.net/img/GoogleMap.png">
            </div>
        </div>
    </mat-card>
</div>