<nav class="navbar navbar-expand-lg navbar-light sticky-top">
  <a class="navbar-brand" style="margin-left:7%">
    <img src="../../../../assets/logo.png" width="90" height="48" alt="">
    <button mat-icon-button (click)="onSideBarToggle()"><mat-icon>menu</mat-icon></button>
  </a>
  <div class="container">


    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <!--<li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color':'rgb(73, 84, 137)' }" (click)="onProfile()" aria-current="page" [routerLink]="'/'">Profile</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link" [ngStyle]="{ 'color':'rgb(73, 84, 137)' }" (click)="onLogout()" aria-current="page" [routerLink]="'/'">Logout</a>
        </li>
      </ul></div>
  </div>
</nav>
<mat-sidenav-container  class="example-container" autosize>
	<mat-sidenav *ngIf="display" #sidenav class="example-sidenav" mode="side" opened="true">
    <p-panelMenu   [model]="panelContentItems" [style]="{'width':'230px'}">

    </p-panelMenu>
	</mat-sidenav>
  <router-outlet></router-outlet>
</mat-sidenav-container>




<!-- <router-outlet></router-outlet> -->

