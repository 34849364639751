import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ChineseSizeService {

    constructor(private http: HttpClient) { }

    getById(id: string) {
        return this.http.get(`${environment.baseUrl}chinese/${id}`, {
            headers: new HttpHeaders({
                token: localStorage.getItem('token')!,
            }),
        });
    }

    getByType(type: string) {
        return this.http.get(`${environment.baseUrl}chinese/type/${type}`, {
            headers: new HttpHeaders({
                token: localStorage.getItem('token')!,
            }),
        });
    }


}