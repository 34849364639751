import { Component } from '@angular/core';
import { LanguageService } from './@Core/service/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Efe Etiket';
  constructor(private languageService: LanguageService) {
    //console.log(languageService.data);
  }
  setLang(lang: string) {
    this.languageService.use(lang);
  }
}
