import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class RussianProductTypeService{

    constructor(private http: HttpClient) {}

    getAll(): Observable<any> {
        return this.http.get(environment.baseUrl+'type/rus', {
          headers: new HttpHeaders({
            token: localStorage.getItem('token')!,
          }),
        });
      }
    
      save(type: any) {
        return this.http.post(environment.baseUrl+'type/rus', type, {
          headers: new HttpHeaders({
            token: localStorage.getItem('token')!,
          }),
        });
      }
    
      deleteById(id: string) {
        return this.http.delete(`${environment.baseUrl}type/rus/${id}`, {
          headers: new HttpHeaders({
            token: localStorage.getItem('token')!,
          }),
        });
      }

}