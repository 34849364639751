import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WarningMessage } from "src/app/pages/online-order/dto/WarningMessage";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class WarningMessageService{

    baseUrl = environment.baseUrl+'warning/';

    constructor(private http: HttpClient){

    }

    getAllWarnings():Observable<any>{

        return this.http.get(this.baseUrl, {
            headers: new HttpHeaders({
                'token': localStorage.getItem('token')!
            })
        })

    }

    saveWarning(warning: WarningMessage){

        return this.http.post(this.baseUrl, warning, {
            headers: new HttpHeaders({
                'token': localStorage.getItem('token')!
            })
        })

    }

    getWarningListByType(type: string):any{

        return this.http.get(this.baseUrl + 'type/' + type, {
            headers: new HttpHeaders({
                'token': localStorage.getItem('token')!
            })
        })

    }

    deleteWarningById(id: string){

        return this.http.delete(this.baseUrl + id, {
            headers: new HttpHeaders({
                'token': localStorage.getItem('token')!
            })
        })

    }
}
