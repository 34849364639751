<mat-horizontal-stepper>
    <mat-step>
        <ng-template matStepLabel>Select Chinese Sizes</ng-template>
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Women Top">
                <mat-selection-list [(ngModel)]="selectedOptions[0]">
                    <mat-list-option *ngFor="let item of options['WOMEN_TOP']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Women Bottom">
                <mat-selection-list [(ngModel)]="selectedOptions[1]">
                    <mat-list-option *ngFor="let item of options['WOMEN_BOTTOM']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Men Top">
                <mat-selection-list [(ngModel)]="selectedOptions[2]">
                    <mat-list-option *ngFor="let item of options['MEN_TOP']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Men Top PRM">
                <mat-selection-list [(ngModel)]="selectedOptions[3]">
                    <mat-list-option *ngFor="let item of options['MEN_TOP_PRM']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Men Bottom">
                <mat-selection-list [(ngModel)]="selectedOptions[4]">
                    <mat-list-option *ngFor="let item of options['MEN_BOTTOM']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Men Bottom PRM">
                <mat-selection-list [(ngModel)]="selectedOptions[5]">
                    <mat-list-option *ngFor="let item of options['MEN_BOTTOM_PRM']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Girls Top">
                <mat-selection-list [(ngModel)]="selectedOptions[6]">
                    <mat-list-option *ngFor="let item of options['GIRLS_TOP']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Girls Bottom">
                <mat-selection-list [(ngModel)]="selectedOptions[7]">
                    <mat-list-option *ngFor="let item of options['GIRLS_BOTTOM']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Boys Top">
                <mat-selection-list [(ngModel)]="selectedOptions[8]">
                    <mat-list-option *ngFor="let item of options['BOYS_TOP']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Boys Bottom">
                <mat-selection-list [(ngModel)]="selectedOptions[9]">
                    <mat-list-option *ngFor="let item of options['BOYS_BOTTOM']" 
                        [value]="item"
                        style="display:inline-block; width:33%;" 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Unisex Top">
                <mat-selection-list [(ngModel)]="selectedOptions[10]">
                    <mat-list-option *ngFor="let item of options['UNISEX_TOP']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Unisex Bottom">
                <mat-selection-list [(ngModel)]="selectedOptions[11]">
                    <mat-list-option *ngFor="let item of options['UNISEX_BOTTOM']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        {{item.normal}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
            <mat-tab label="Others">
                <mat-selection-list [(ngModel)]="selectedOptions[12]">
                    <mat-list-option *ngFor="let item of options['OTHERS']" 
                        [value]="item"
                        style="display:inline-block; width:33%; " 
                        checkboxPosition="before">
                        <div class="row">
                            <div class="col-md-2" style="margin-top: 5%;">
                                {{item.normal}}
                            </div>
                            <mat-form-field class="col-md-10">
                                <mat-label>Size (cm)</mat-label>
                                <input [(ngModel)]="item.chinese" matInput>
                            </mat-form-field>

                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </mat-tab>
        </mat-tab-group>
        <div style="float: right;">
            <button mat-button matStepperNext>Next</button>
          </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Enter Quantity</ng-template>

        <mat-list>
            <div class="row">
                <div *ngFor="let header of headers; let i = index"  class="col-md-3">
                    <div *ngIf="selectedOptions[i].length > 0">
                        <div mat-subheader>{{header}}</div>
                        <mat-list-item *ngFor="let item of selectedOptions[i]">
                            <div class="row">
                                <div class="col-md-3" style="padding-top: 9%; text-align: right;">{{item.normal}}</div>
                                <div class="col-md-9">
                                    <mat-form-field>
                                        <mat-label>Quantity</mat-label>
                                        <input [(ngModel)]="item.quantity" [formControl]="formControl" matInput required>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-list-item>  
                    </div>
                </div>

            </div>
            
        </mat-list>

        <div mat-dialog-actions style="float: right;">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="onNoClick()">Cancel</button>
            <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
        </div>

    </mat-step>

</mat-horizontal-stepper>


