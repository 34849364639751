import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fabricFeaturesPart } from 'src/app/pages/online-order/dto/FabricFeaturePart';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FabricFeatureService {

  fabrics:any[] = [
    {
      id: '1',
      value: {
        gb: 'Back part',
        de: 'Hinterteil',
        fr: 'Partie arrière',
        es: 'Parte trasera',
        it: 'Parte de trás',
        pt: 'Parte de trás',
        gr: 'Πλάτη',
        ru: 'Спинка',
        ch: '后片',
      },
    },
    {
      id: '2',
      value: {
        gb: 'Body lining',
        de: 'Rumpffutter',
        fr: 'Doublure corps',
        es: 'Forro del cuerpo',
        it: 'Fodera Corpo',
        pt: 'Forro do Corpo',
        gr: 'Φόδρα σώματος',
        ru: 'Основная подкладка',
        ch: '身里',
      },
    },
  ];

  constructor(private http: HttpClient) {}



  getAllFabrics(): Observable<any> {
    return this.http.get(environment.baseUrl+'fabric', {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }


  saveFabric(fabric: fabricFeaturesPart) {
    return this.http.post(environment.baseUrl+'fabric', fabric, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  getFabricById(type: string) {
    return this.http.get(`${environment.baseUrl}fabric/${type}`, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }

  deleteFabricById(id: string) {
    return this.http.delete(`${environment.baseUrl}fabric/${id}`, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token')!,
      }),
    });
  }
}
