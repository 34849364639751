import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { faTag, faTags, faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  totalUserNumber = 10;
  totalWashedInstructionNumber = 20;
  totalCompletedInstructionNumber = 70;

  // icons for dasboard cards
  userIcon = faUser;
  washedIcon = faTag;
  completedIcon = faTags;

  data: any;

  chartOptions: any;

  subscription!: Subscription;


  constructor() {}

  ngOnInit() {
    this.data = {
      labels: ['washed instruction', 'completed instructions'],
      datasets: [
        {
          data: [
            this.totalWashedInstructionNumber,
            this.totalCompletedInstructionNumber,
          ],
          backgroundColor: ['#fa8564', '#1FB5AD'],
          hoverBackgroundColor: ['#fa8564', '#1FB5AD'],
        },
      ],
    };
  }
}
