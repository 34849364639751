<h1 mat-dialog-title>Select Russian Product Type</h1>
<div mat-dialog-content>
    <div class="row">
        <mat-form-field class="col-md-12" appearance="fill">
            <mat-label>Product Type</mat-label>
            <mat-select [(value)]="data">
                <mat-option *ngFor="let type of types" [value]="type">
                    {{type.name}} - {{type.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions style="float: right;">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
